import { fonts } from 'src/styles/variables';

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

const Table = {
  baseStyle: {},
  variants: {
    costBreakdown: {
      table: {
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontSize: '14px',
      },
      td: {
        ...numericStyles,
        textAlign: 'start',
        py: '6px',
        px: 0,
        lineHeight: '24px',
        color: 'podcastShade500',
      },
      tfoot: {
        ...numericStyles,
        fontSize: '16px',
        fontFamily: fonts.bold,
        borderTop: '1px solid',
        borderColor: 'podcastShade200',
        td: {
          pt: '14px',
          color: 'podcastShade600',
        },
      },
      caption: {
        pt: 0,
        mt: 0,
        px: 0,
        fontFamily: fonts.normal,
        textAlign: 'right',
        color: 'podcastShade500',
      },
    },
    transcript: {
      td: {
        px: '5px',
        py: '5px',
        verticalAlign: 'top',
      },
    },
  },
};

export default Table;
