import { Box } from '@chakra-ui/react';
import { MenuProvider } from 'src/components/menu/MenuContext';
import { AnimatePresence } from 'framer-motion';
import { Suspense } from 'react';
import { useUserCache } from 'src/apollo/cache';
import { IntercomProvider } from 'react-use-intercom';
import { FallbackAuth, FallbackUnauth } from 'src/components/fallback/Fallback';
import useThirdPartyApps from 'src/components/routes/useThirdPartyApps';
import useNetworkNotifications from 'src/hooks/useNetworkNotifications';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import useIsPublicRoute from './hooks/useIsPublicRoute';
import useRouterRedirects from './hooks/useRouterRedirects';
import AppRoutes from './AppRoutes';
import useShouldIntercomInitialise from './hooks/useShouldIntercomInitialise';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route element={<RouterRedirects />}> {AppRoutes} </Route>
    </Route>,
  ),
);

function App() {
  useNetworkNotifications();
  useThirdPartyApps();
  return <RouterProvider router={router} />;
}

function RouterRedirects() {
  useRouterRedirects();
  return <Outlet />;
}

function Layout() {
  const isPublicRoute = useIsPublicRoute();
  const user = useUserCache();
  const shouldIntercomInitialize = useShouldIntercomInitialise();

  return (
    <Box
      css={{
        p: {
          lineHeight: '1.5em',
          letterSpacing: '-0.1px',
        },
        'button:focus': {
          outline: 'none',
        },
        ':focus': {
          outline: 'none',
        },
        'button[disabled]': {
          opacity: '0.4',
        },
      }}
    >
      <IntercomProvider
        appId="c80owukf"
        shouldInitialize={shouldIntercomInitialize}
        autoBoot
        autoBootProps={{
          name: user && `${user?.firstName || ''} ${user?.lastName || ''}`,
          email: user?.email,
          userId: user?.id,
        }}
      >
        <AnimatePresence initial={false}>
          <MenuProvider>
            <Suspense fallback={isPublicRoute ? <FallbackUnauth /> : <FallbackAuth />}>
              <Outlet />
            </Suspense>
          </MenuProvider>
        </AnimatePresence>
      </IntercomProvider>
    </Box>
  );
}

export default App;
