import { numberInputAnatomy } from '@chakra-ui/anatomy';
import colors from 'src/theme/colors';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/react';
import Input from './Input';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  numberInputAnatomy.keys,
);

const $height = cssVar('input-height');
const $padding = cssVar('input-padding');

const primary = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'podcastShade300',
    borderRadius: '12px',
    outlineOffset: 0,
    _hover: {
      boxShadow: 'none',
    },
    _focusWithin: {
      borderColor: 'podcastPurple',
      outlineWidth: '1px',
      outlineStyle: 'solid',
      outlineColor: 'podcastPurple',
      _invalid: {
        borderColor: 'AlituPink',
        outlineWidth: '1px',
        outlineColor: 'AlituPink',
      },
    },
    _invalid: {
      border: `1px solid ${colors.AlituPink}`,
      boxShadow: 'inset 0px -3px 6px rgba(239, 57, 113, 0.05)',
    },
  },
});

const small = defineStyle({
  [$height.variable]: '36px',
  fontSize: '13px',
});

const large = defineStyle({
  [$height.variable]: '48px',
  [$padding.variable]: '20px',
  fontSize: '16px',
});

const sizes = {
  small: definePartsStyle({ root: small, field: small, stepperGroup: small, stepper: small }),
  large: definePartsStyle({ root: large, field: large, stepperGroup: large, stepper: large }),
};

export default defineMultiStyleConfig({
  baseStyle: Input.baseStyle,
  variants: { primary },
  defaultProps: { variant: 'primary', size: 'large' },
  sizes,
});
