import { Flex, Button } from '@chakra-ui/react';
import { colours } from 'src/styles/variables';
import Icons from '../Icons';

export default function MobileTopNav() {
  return (
    <Flex
      as="section"
      h="100%"
      w="100vw"
      bg="podcastPurple"
      px="20px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Icons.AlituLogoWithText />
      <Menu />
    </Flex>
  );
}

function Menu() {
  return (
    <Button
      variant="flat"
      as="nav"
      bg="podcastPurple"
      textTransform="capitalize"
      leftIcon={<MenuIcon />}
    >
      menu
    </Button>
  );
}

function MenuIcon() {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 1H1M17 6H1M17 11H1"
        stroke={colours.white}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
