import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import Cancel from 'src/assets/icons/CrossIcon';
import { colours, fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import { useCacheBusterContext } from './CacheBusterContext';

export default function CachePopup() {
  const cacheBuster = useCacheBusterContext();

  const transform = cacheBuster.isOpen
    ? 'translateX(calc(100% - 420px))'
    : 'translateX(calc(100% + 20px))';

  return (
    <Flex flexDirection="column" position="fixed" transform={transform} {...cachePopupStyle}>
      <Box mb="20px" cursor="pointer">
        <IconButton
          aria-label="close"
          icon={<Cancel color={colors.podcastShade600} />}
          onClick={cacheBuster.onClose}
          variant="empty"
          height="16px"
        />
      </Box>
      <Box textAlign="center">
        <Text color={colours.purple}>
          A new version of Alitu is available. Would you like to update?
        </Text>
        <br />
        <Button fontFamily={fonts.bold} onClick={cacheBuster.refresh}>
          Update
        </Button>
      </Box>
    </Flex>
  );
}

const cachePopupStyle = {
  maxWidth: '350px',
  background: colours.white,
  bottom: '100px',
  right: '0',
  transition: '0.5s linear all',
  borderRadius: '8px',
  boxShadow: '0 1px 8px 3px rgba(0, 0, 0, 0.07)',
  padding: '20px',
  fontSize: '1.3em',
  alignItems: 'flex-end',
  zIndex: '9',
};
