/**
 * @example getArtworkVariant('https://feeds.alitu.com/59376958/da63b9c6-f17b-4621-89c2-32cfbe112060.jpg', '480px')
 * @returns 'https://feeds.alitu.com/59376958/da63b9c6-f17b-4621-89c2-32cfbe112060-480px.jpg'
 */
export default function getArtworkVariant(
  url: string | null | undefined,
  variant: '120px' | '480px' | '700px',
) {
  if (!url) return '';
  const urlWithoutExtension = url.split('.').slice(0, -1).join('.');
  const extension = url.split('.').pop();
  return `${urlWithoutExtension}-${variant}.${extension}`;
}
