import { ApolloClient } from '@apollo/client';
import {
  GetSubscriptionDocument,
  ShowDocument,
  ShowFragmentFragment,
  Subscription,
  User,
  UserDocument,
} from 'src/generated/graphql';

type Client = ApolloClient<object>;

export function updateSubscriptionCache({
  subscription,
  client,
}: {
  subscription: Subscription;
  client: Client;
}) {
  if (!subscription) return;
  const { id: newId, __typename } = subscription;

  const existingSubscription = client.cache.readQuery({ query: GetSubscriptionDocument }) as {
    subscription: Subscription;
  };

  client.writeQuery({ query: GetSubscriptionDocument, data: { subscription } });

  // The subscription ID can change, so we need to evict the old object from the cache
  if (existingSubscription && existingSubscription.subscription.id !== newId) {
    const oldObjectId = `${__typename}:${existingSubscription.subscription.id}`;
    client.cache.evict({ id: oldObjectId });
    client.cache.gc();
  }
}

export function updateUserCache({ user, client }: { user: User; client: Client }) {
  client.writeQuery({ query: UserDocument, data: { user } });
}

export function updateShowCache({ show, client }: { show: ShowFragmentFragment; client: Client }) {
  client.writeQuery({ query: ShowDocument, data: { show } });
}
