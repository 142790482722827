import {
  Box,
  Grid,
  GridProps,
  Heading,
  HeadingProps,
  Text,
  Link,
  BoxProps,
} from '@chakra-ui/react';
import { LeftArrow } from 'src/assets/icons/LeftArrow';
import { colours, fonts } from 'src/styles/variables';
import { Link as ReachLink } from 'react-router-dom';

interface PageHeaderContainerProps extends GridProps {
  arrowProps?: {
    text: string;
    link: string;
  };
  headerProps?: BoxProps;
}
export function PageHeaderContainer({
  arrowProps,
  headerProps,
  ...props
}: PageHeaderContainerProps) {
  return (
    <Box
      as="header"
      w="100%"
      p="25px 60px 30px"
      bg={colours.white}
      borderBottom="2px solid"
      borderColor="podcastShade200"
      h="fit-content"
      {...headerProps}
    >
      <Grid
        templateRows="repeat(3, auto)"
        rowGap="0.3125rem"
        alignItems="center"
        mx="auto"
        {...props}
      >
        {arrowProps && (
          <Link
            as={ReachLink}
            to={arrowProps.link}
            color="podcastShade500"
            _hover={{ color: 'podcastShade500' }}
            _visited={{ color: 'podcastShade500' }}
            fontFamily={fonts.bold}
            fontSize="18px"
            textDecoration="none"
            display="inline-flex"
            alignItems="baseline"
            gridGap="6px"
            mb="5px"
          >
            <LeftArrow />
            {arrowProps.text}
          </Link>
        )}
        {props.children}
      </Grid>
    </Box>
  );
}

export function PageHeading(props: HeadingProps) {
  return (
    <Heading size="h1" as="h1" {...props}>
      {props.children}
    </Heading>
  );
}

export function PageSubheading(props: HeadingProps) {
  return (
    <Text size="p2" variant="lighter" {...props}>
      {props.children}
    </Text>
  );
}
