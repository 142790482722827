import { useUserCache, useSubscriptionCache, useShowCache } from 'src/apollo/cache';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import parseCookie from 'src/utils/parseCookie';
import { useEffect } from 'react';
import { Permissions, ShowFragmentFragment, Subscription, User } from 'src/generated/graphql';
import { COOKIE_KEYS, getUtmCookies } from 'src/utils/getUtmCookies';
import { IToggle } from '@unleash/proxy-client-react';
import { usePermissionsContext } from '../permissions/PermissionsContext';
import { useGetAllFeatureFlags } from '../feature-flags/useFeatureFlag';

const isAdmin = (userRole?: string) => (userRole === undefined ? false : userRole === 'admin');

export default function useThirdPartyApps() {
  const user = useUserCache();
  const { permissions } = usePermissionsContext();
  const subscription = useSubscriptionCache();
  const show = useShowCache();
  const featureFlags = useGetAllFeatureFlags();

  useEffect(() => {
    if (shouldInitiate(user) || process.env.NODE_ENV === 'staging') {
      initLogrocket(user, process.env.NODE_ENV as 'staging' | 'production');
    }
    if (shouldInitiate(user)) {
      initSentry(user);
      initSegment(user, subscription, show, permissions, featureFlags);
    }
  }, [user, subscription, show, permissions, featureFlags]);
}

function shouldInitiate(user: User | undefined) {
  // do not use third party apps if admin is using user's account
  return (
    process.env.NODE_ENV === 'production' &&
    !(parseCookie()['is-alitu-admin'] || isAdmin(user?.role))
  );
}

function formatFeatureFlags(flags: IToggle[]) {
  let result = {};

  const kebabToSnakeCase = (string: string) => string.replace(/-/g, '_');

  if (flags) {
    // Prepend the flag name with "ff_" (as in feature flag) and convert to snake case
    // to match other properties sent to Segment
    result = Object.fromEntries(
      flags?.map(flag => [`ff_${kebabToSnakeCase(flag.name)}`, flag.enabled]),
    );
  }
  return result;
}

function getISODateAtMidnight(date: number) {
  const toMidnight = new Date(date).setUTCHours(0, 0, 0, 0);
  return new Date(toMidnight).toISOString();
}

export function initSegment(
  user: User | undefined,
  subscription: Subscription | undefined,
  show: ShowFragmentFragment | undefined,
  permissions: Permissions | null | undefined,
  featureFlags: IToggle[],
) {
  try {
    // flags have to be in object to then spread them,
    // Segment does not work well with arrays or other complex entities.
    // We only want feature flag name and boolean value if it's enabled. e.g: flag: true
    const formattedFeatureFlags = formatFeatureFlags(featureFlags);

    if (!user || !show) return;
    const utmCookies = getUtmCookies();
    // @ts-ignore
    window.analytics?.identify(user.id, {
      user_id: user.id,
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      tier: permissions?.tier,
      trial_start_date: user?.createdAt && getISODateAtMidnight(+user.createdAt), // Posibly can be removed, needs discussion with Growth/Support team
      current_period_end:
        subscription?.current_period_end &&
        getISODateAtMidnight(subscription.current_period_end * 1000),
      subscription_plan: subscription?.plans?.[0]?.interval
        ? `${subscription.plans[0].interval}ly`
        : undefined,
      subscription_status: subscription?.status,
      coupon_used: subscription?.discount?.coupon?.id,
      host_is_connected: !!show.host,
      host_connected: show.host,
      email_opt_in: user.emailOptIn,
      is_tester: user.isTester,
      heard_about_from: user.heardAboutFrom,
      podcast_purpose: user.podcastPurpose,
      experience_level: user.experienceLevel,
      signed_up_at: user?.createdAt && getISODateAtMidnight(+user.createdAt),
      podcast_website: show.subdomain
        ? `${show.subdomain}${process.env.NODE_ENV === 'staging' ? '.staging' : ''}.alitu.com`
        : null,
      is_paused: !!subscription?.pause_collection,
      pause_resumes_at:
        subscription?.pause_collection?.resumes_at &&
        getISODateAtMidnight(subscription.pause_collection.resumes_at * 1000),
      ...formattedFeatureFlags,
      distribution: {
        apple: show.links?.apple,
        spotify: show.links?.spotify,
      },
      [COOKIE_KEYS.FIRST_TOUCH.SOURCE]: utmCookies?.firstTouch?.source,
      [COOKIE_KEYS.FIRST_TOUCH.MEDIUM]: utmCookies?.firstTouch?.medium,
      [COOKIE_KEYS.FIRST_TOUCH.CAMPAIGN]: utmCookies?.firstTouch?.campaign,
      [COOKIE_KEYS.FIRST_TOUCH.CONTENT]: utmCookies?.firstTouch?.content,
      [COOKIE_KEYS.LAST_TOUCH.SOURCE]: utmCookies?.lastTouch?.source,
      [COOKIE_KEYS.LAST_TOUCH.MEDIUM]: utmCookies?.lastTouch?.medium,
      [COOKIE_KEYS.LAST_TOUCH.CAMPAIGN]: utmCookies?.lastTouch?.campaign,
      [COOKIE_KEYS.LAST_TOUCH.CONTENT]: utmCookies?.lastTouch?.content,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    LogRocket.captureException(error as Error);
  }
}

function initLogrocket(user: User | undefined, environment: 'staging' | 'production') {
  LogRocket.init(`bnpdse/alitu-${environment}`, {
    network: {
      requestSanitizer: request => {
        // Clear body of requests on pages containing sensitive data (passwords)
        if (
          request.url.toLowerCase().indexOf('login') !== -1 ||
          request.url.toLowerCase().indexOf('signup') !== -1 ||
          request.url.toLowerCase().indexOf('my-account') !== -1
        ) {
          request.body = undefined;
        }
        return request;
      },
    },
  });
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setUser({ id: user?.id });
      scope.setExtra('sessionURL', sessionURL);
    });
  });
  if (user?.id) {
    LogRocket.identify(user.id);
  }
}

function initSentry(user: User | undefined) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === 'staging' ? 1.0 : 0.2,
    replaysOnErrorSampleRate: process.env.NODE_ENV === 'staging' ? 1.0 : 0.2,
    ignoreErrors: [
      'Invariant Violation: Cannot call hover while not dragging.',
      'AbortError: The play() request was interrupted by a call to pause()',
      "Cannot read property 'getCurrentTime' of null",
      "null is not an object (evaluating 'this.backend.getCurrentTime')",
    ],
  });
  if (user) {
    Sentry.setUser({ id: user.id });
  }
}
