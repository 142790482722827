import { Button, Flex, Text } from '@chakra-ui/react';
import { ShowsDocument, useRenewShowMutation } from 'src/generated/graphql';
import assert from 'src/utils/assert';
import { useMyShowsContext } from './MyShowsContext';
import useGetExtraShowCost from './useGetExtraShowCost';

export default function RenewShow() {
  const { setContentType, showToRenew } = useMyShowsContext();
  assert(showToRenew, 'showToRenew is undefined');
  const { cost, currencySymbol } = useGetExtraShowCost();
  const [renewShow, { loading }] = useRenewShowMutation({
    onCompleted: () => {
      setContentType('show-renewed-confirmation');
    },
    variables: {
      id: showToRenew,
    },
    refetchQueries: [ShowsDocument],
  });

  return (
    <>
      <Text>{`The subscription for this show will renew at ${currencySymbol}${cost}`}</Text>
      <Flex mt="20px" gap="10px">
        <Button isLoading={loading} onClick={() => renewShow()}>
          Renew
        </Button>
        <Button
          isDisabled={loading}
          variant="tertiary"
          onClick={() => setContentType('list-shows')}
        >
          Cancel
        </Button>
      </Flex>
    </>
  );
}
