import { useMemo } from 'react';
import { useUserCache } from 'src/apollo/cache';
import { useLocation } from 'react-router-dom';

function useIsPublicRoute() {
  const location = useLocation();
  const userCache = useUserCache();

  return useMemo(
    () =>
      ['/signup', '/login', '/forgot', '/password-reset', '/join-studio'].some(path => {
        const pathDoesMatch = location.pathname.includes(path);
        if (path === '/join-studio' && pathDoesMatch) {
          // if the user object doesn't exist, treat the path as public, otherwise private
          return !userCache;
        }
        return pathDoesMatch;
      }),
    [location.pathname, userCache],
  );
}

export default useIsPublicRoute;
