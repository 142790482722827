import { Global } from '@emotion/react';

import MontserratMedium from '../assets/fonts/Montserrat-Medium.woff';
import MontserratSemiBold from '../assets/fonts/Montserrat-SemiBold.woff';

function Fonts() {
  return (
    <Global
      styles={`
    /* latin */
  @font-face {
    font-family: 'Montserrat-SemiBold';
    src: local('Montserrat-SemiBold'), url(${MontserratSemiBold}) format('woff');
  }
  
  @font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url(${MontserratMedium}) format('woff');
  }
  `}
    />
  );
}

export default Fonts;
