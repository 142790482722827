import { Button, ButtonProps, Flex } from '@chakra-ui/react';
import { colours } from 'src/styles/variables';
import useNavigateTo from '../hooks/useNavigateTo';
import usePageSelected from '../hooks/usePageSelected';
import Icons, { IconComp } from '../Icons';

export default function MobileBottomNav() {
  const selected = usePageSelected();
  const navigateTo = useNavigateTo();

  return (
    <Flex
      as="nav"
      w="100vw"
      h="60px"
      bg="podcastPurple"
      px="15px"
      justifyContent="center"
      position="sticky"
      bottom="0"
    >
      <NavButton
        Icon={Icons.Episodes}
        isSelected={selected.episodes}
        onClick={navigateTo.episodes}
        text="episodes"
      />
      <NavButton
        Icon={Icons.CallRecorder}
        isSelected={selected.callRecorder}
        onClick={navigateTo.callRecorder}
        text="call rec"
      />
      <NavButton
        Icon={Icons.ClipGenie}
        isSelected={selected.clipGenie}
        onClick={navigateTo.clipGenie}
        text="editor"
      />
      <NavButton
        Icon={Icons.Library}
        isSelected={selected.library}
        onClick={navigateTo.library}
        text="library"
      />
    </Flex>
  );
}

function NavButton({
  isSelected,
  Icon,
  text,
  ...props
}: ButtonProps & { isSelected: boolean; Icon: IconComp; text: string }) {
  return (
    <Button
      variant="empty"
      w="79px"
      h="100%"
      bg={isSelected ? colours.white : 'transparent'}
      color={isSelected ? 'podcastPurple' : colours.white}
      textTransform="capitalize"
      borderRadius="0"
      {...props}
    >
      <Flex w="100%" direction="column" alignItems="center" gridGap="1px">
        <Icon color={isSelected ? 'podcastPurple' : colours.white} />
        {text}
      </Flex>
    </Button>
  );
}
