import colors from 'src/theme/colors';
import { fonts } from 'src/styles/variables';

const FormLabel = {
  baseStyle: {
    fontFamily: fonts.bold,
    fontSize: '1rem',
    pl: 0,
    lineHeight: '1.5rem',
    color: 'podcastShade600',
    width: 'max-content',
  },
  variants: {
    primary: {},
    insideInput: {
      fontFamily: fonts.normal,
      pl: '20px',
      fontSize: 13,
      lineHeight: '1.2rem',
      borderRadius: 12,
      position: 'absolute',
      top: 0,
      left: 0,
      h: '60px',
      w: '100%',
      pt: '8px',
      boxShadow: 'inset 0px -3px 10px rgba(205, 204, 227, 0.2)',

      border: '1px solid',
      borderColor: 'podcastShade300',
      color: 'podcastShade600',
      _groupHover: {
        boxShadow: 'none',
      },
      _focus: {
        border: '2px solid',
        pt: '7px',
        pl: '19px',
        borderColor: colors.podcastPurple,
        _invalid: {
          border: `2px solid ${colors.AlituPink}`,
        },
      },
      _invalid: {
        border: `1px solid ${colors.AlituPink}`,
        boxShadow: 'inset 0px -3px 6px rgba(239, 57, 113, 0.05)',
      },
      _disabled: {
        background: colors.podcastShade100,
        opacity: 1,
      },
    },
    large: {
      fontFamily: fonts.bold,
      fontSize: '1rem',
      pl: 0,
      lineHeight: '1.5rem',
      color: 'podcastShade600',
      width: 'max-content',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default FormLabel;
