export const COOKIE_KEYS = {
  FIRST_TOUCH: {
    SOURCE: 'first_touch_attr_source',
    MEDIUM: 'first_touch_attr_medium',
    CAMPAIGN: 'first_touch_attr_campaign',
    CONTENT: 'first_touch_attr_content',
  },
  LAST_TOUCH: {
    SOURCE: 'last_touch_attr_source',
    MEDIUM: 'last_touch_attr_medium',
    CAMPAIGN: 'last_touch_attr_campaign',
    CONTENT: 'last_touch_attr_content',
  },
};

type UtmCookies = { source: string; medium: string; campaign: string; content: string };

/**
 * @description
 * Cookies are set in the alitu.com marketing site. We can access them here.
 */
export function getUtmCookies(): {
  firstTouch: UtmCookies;
  lastTouch: UtmCookies;
} | null {
  type Cookies = { [key: string]: string };
  const cookies: Cookies = document.cookie.split(';').reduce((previous, current) => {
    const [key, value] = current.trim().split('=');
    return { ...previous, [key]: value };
  }, {});

  if (cookies[COOKIE_KEYS.FIRST_TOUCH.SOURCE]) {
    return {
      firstTouch: {
        source: cookies[COOKIE_KEYS.FIRST_TOUCH.SOURCE],
        medium: cookies[COOKIE_KEYS.FIRST_TOUCH.MEDIUM],
        campaign: cookies[COOKIE_KEYS.FIRST_TOUCH.CAMPAIGN],
        content: cookies[COOKIE_KEYS.FIRST_TOUCH.CONTENT],
      },
      lastTouch: {
        source: cookies[COOKIE_KEYS.LAST_TOUCH.SOURCE],
        medium: cookies[COOKIE_KEYS.LAST_TOUCH.MEDIUM],
        campaign: cookies[COOKIE_KEYS.LAST_TOUCH.CAMPAIGN],
        content: cookies[COOKIE_KEYS.LAST_TOUCH.CONTENT],
      },
    };
  }

  return null;
}
