import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { generateStripe } from '@chakra-ui/theme-tools';
import colors from '../colors';

const Progress: ComponentStyleConfig = {
  baseStyle: {
    filledTrack: {
      bg: 'podcastPurple500',
      borderRadius: '10px',
      height: '7px',
    },
    track: {
      background: 'podcastShade200',
      height: '7px',
      borderRadius: '10px',
      width: '100%',
      zIndex: '9',
    },
  },
  variants: {
    loading: {
      filledTrack: {
        ...generateStripe('1rem', 'rgba(0,0,0,0.1)'),
        bg: 'podcastShade300',
      },
    },
    turquoise: {
      filledTrack: {
        bg: 'podcastBlue600',
      },
    },
    orange: {
      filledTrack: {
        bg: 'AlituOrange',
      },
    },

    grey: {
      filledTrack: {
        bg: 'podcastShade300',
      },
    },
    upload: {
      filledTrack: {
        height: '12px',
      },
      track: {
        maxWidth: '200px',
        height: '12px',
      },
    },
    miniPlayer: {
      track: {
        background: 'white',
      },
    },
    disabled: {
      track: {
        background: colors.podcastShade100,
        opacity: '0.8',
      },
    },
    clipGenie: {
      filledTrack: {
        height: '12px',
      },
      track: {
        height: '12px',
      },
    },
    comparison: {
      filledTrack: {
        h: '36px',
      },
      track: {
        h: '36px',
        w: '100%',
        background: colors.podcastPurple,
        div: {
          bg: `${colors.podcastBlue} !important`,
          borderRadius: 'none',
        },
      },
    },
    statistics: {
      filledTrack: {
        h: '36px',
      },
      track: {
        h: '36px',
        w: '580px',
      },
    },
    micVolume: {
      filledTrack: {
        h: '24px',
      },
      track: {
        h: '24px',
      },
    },
    participantVolume: {
      filledTrack: {
        h: '12px',
        borderRadius: '2px',
        background: `repeating-linear-gradient(
            to right,
            ${colors.podcastBlue},
            ${colors.podcastBlue} 2px,
            ${colors.podcastBlue600} 2px,
            ${colors.podcastBlue600} 4px
          );
          `,
      },
      track: {
        border: `2px solid ${colors.white}`,
        borderRadius: '4px',
        background: colors.white,
        h: '16px',
        w: '24px',
      },
    },
    studioVolume: {
      filledTrack: {
        h: '22px',
      },
      track: {
        border: `1px solid ${colors.podcastShade300}`,
        background: colors.white,
        h: '24px',
        w: '48px',
      },
    },
    volumeDisabled: {
      filledTrack: {
        h: '22px',
      },
      track: {
        border: `1px solid ${colors.podcastShade300}`,
        background: colors.podcastShade100,
        h: '24px',
        w: '48px',
        opacity: '0.5',
      },
    },
  },
};

export default Progress;
