import { colours } from 'src/styles/variables';
import colors from 'src/theme/colors';

const Radio = {
  baseStyle: {
    label: {
      _invalid: {
        color: 'podcastRed600',
      },
    },
    control: {
      bg: colours.white,
      borderColor: 'podcastShade300',
      boxShadow: 'inset 0px -2px 4px rgba(61, 58, 117, 0.09)',

      _checked: {
        bg: colors.podcastPurple,
        borderColor: colors.AlituPumpPurple,
        boxShadow: 'inset 0px -2px 4px rgba(61, 58, 117, 0.25)',
        color: colours.white,

        _hover: {
          bg: colors.podcastPurple,
          boxShadow: 'none',
        },

        _invalid: {
          bg: colours.white,
          color: 'podcastRed600',
          borderColor: 'podcastRed600',

          _focus: {
            boxShadow: '0px 0px 0px 2px rgba(255, 100, 100, 0.5)',
          },
        },
      },

      _indeterminate: {
        bg: colors.podcastRed,
      },

      _disabled: {
        opacity: 0.4,
        boxShadow: 'none',
      },

      _focus: {
        boxShadow: '0px 0px 0px 2px rgba(101, 85, 205, 0.5)',
      },

      _invalid: {
        borderColor: 'podcastRed600',
        _focus: {
          boxShadow: '0px 0px 0px 2px rgba(255, 100, 100, 0.5)',
        },
      },

      _hover: {
        boxShadow: 'none',
      },
    },
  },
};

export default Radio;
