import { PlacementWithLogical, Tooltip, TooltipProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { fonts } from 'src/styles/variables';

interface SmallTooltipProps extends TooltipProps {
  label: string;
  children: ReactNode;
  placement?: PlacementWithLogical;
}

export default function SmallTooltip({ label, children, placement, ...props }: SmallTooltipProps) {
  return (
    <Tooltip
      placement={placement || 'bottom'}
      backgroundColor="podcastShade600"
      label={label}
      maxW="173px"
      fontFamily={fonts.bold}
      fontSize="14px"
      lineHeight="18px"
      color="podcastShade100"
      padding="4px 9px 4px 6px"
      borderRadius="4px"
      arrowSize={7}
      hasArrow
      {...props}
    >
      {children}
    </Tooltip>
  );
}
