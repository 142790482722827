import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';

const FormError = {
  baseStyle: {
    text: {
      fontFamily: fonts.normal,
      fontSize: 13,
      pl: '20px',
      pr: '12px',
      py: '7px',
      color: 'red',
      bg: '#FEF6F6',
      border: `1px solid ${colors.podcastRed600}`,
      borderRadius: '12px',
      minHeight: '36px',
      justifyContent: 'space-between',
    },
  },
};

export default FormError;
