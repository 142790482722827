const Switch = {
  baseStyle: {
    track: {
      bg: 'podcastShade400',
      p: 0,
      _focus: {
        boxShadow: '0px 0px 0px 2px rgba(160, 160, 160, 0.5)',
      },
      _checked: {
        bg: 'podcastPurple',
        _focus: {
          boxShadow: '0px 0px 0px 2px rgba(101, 85, 205, 0.5)',
        },
        _invalid: {
          bg: 'podcastRed600',
        },
      },
      _invalid: {
        bg: 'podcastRed400',
        _focus: {
          boxShadow: '0px 0px 0px 2px rgba(255, 100, 100, 0.5)',
        },
      },
    },
    thumb: {
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3);',
    },
  },
  sizes: {
    default: {
      container: {
        '--slider-track-width': '43px',
        '--slider-track-height': '22px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export default Switch;
