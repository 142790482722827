import { Box, Divider, Flex, keyframes } from '@chakra-ui/react';
import { memo, useState } from 'react';
import colors from 'src/theme/colors';
import { useMenuContext } from '../MenuContext';
import OpenCloseMenuButton from './components/OpenCloseMenuButton';
import SidebarMenuContainer from './components/SidebarMenuContainer';
import { MenuHeader } from './MenuHeader';
import { NavContainer } from './NavContainer';
import AccountMenuButton from './components/AccountMenuButton';

const collapseKeyframe = keyframes`
from {
  width: 260px;
}
to {
  width: 72px;
}
`;

const expandKeyframe = keyframes`
from {
  clip-path: inset(0 70% 0 0);
}
to {
  clip-path: inset(0 0 0 0);
}
`;

function SidebarMenu() {
  const [animation, setAnimation] = useState<string | undefined>(undefined);
  const [menuState] = useMenuContext();
  const isMenuOpen = menuState === 'open';

  const menuAnimation = `${isMenuOpen ? collapseKeyframe : expandKeyframe} 180ms ease-out`;

  function addAnimation() {
    setAnimation(menuAnimation);
    setTimeout(() => {
      setAnimation(undefined);
    }, 180);
  }

  return (
    <Box
      animation={animation}
      zIndex="9"
      background={`linear-gradient(180deg, ${colors.podcastPurple} 8.33%, ${colors.podcastPurple800} 100%);`}
    >
      <SidebarMenuContainer
        menuState={menuState}
        pb={!isMenuOpen ? '18px' : ''}
        pt={isMenuOpen ? '42px' : '18px'}
        alignItems={!isMenuOpen ? 'center' : 'normal'}
        justifyContent="space-between"
      >
        <MenuHeader />
        <Box position="relative">
          {isMenuOpen && (
            <Divider
              mb="16px"
              mx="auto"
              w="90%"
              borderWidth="2px"
              borderRadius="10px"
              borderColor="podcastPurple"
            />
          )}
          <NavContainer />
          <Flex mb="10px" gap="10px" flexDir={isMenuOpen ? 'row' : 'column'}>
            <AccountMenuButton />
            <OpenCloseMenuButton addAnimation={addAnimation} />
          </Flex>
        </Box>
      </SidebarMenuContainer>
    </Box>
  );
}

export default memo(SidebarMenu);
