import { Button, Text } from '@chakra-ui/react';
import { useSubscriptionCache } from 'src/apollo/cache';
import { ShowFragmentFragment } from 'src/generated/graphql';
import colors from 'src/theme/colors';
import assert from 'src/utils/assert';
import { useMyShowsContext } from './MyShowsContext';

export default function RenewShowButton({ showId }: { showId: ShowFragmentFragment['id'] }) {
  const { setContentType, setShowToRenew, onOpen, isMyAccount } = useMyShowsContext();
  const subscription = useSubscriptionCache();

  function getDaysRemaining() {
    assert(subscription, 'subscription not found in cache');
    const currentPeriodEndDate = new Date(subscription.current_period_end * 1000);
    const currentDate = new Date();
    const timeDifference = Number(currentPeriodEndDate) - Number(currentDate);

    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) - 1; // subtract 1 to account for the current day
    return daysRemaining;
  }

  return (
    <>
      <Text
        as="span"
        color={colors.podcastShade500}
        fontSize="13px"
        mr="10px"
      >{`${getDaysRemaining()} days remaining`}</Text>
      <Button
        variant="quaternary"
        bg={colors.podcastLightBlue100}
        color={colors.podcastBlue700}
        mr="10px"
        onClick={() => {
          setContentType('renew-show');
          setShowToRenew(showId);
          if (isMyAccount) {
            onOpen();
          }
        }}
      >
        Renew
      </Button>
    </>
  );
}
