import { useShowsQuery } from 'src/generated/graphql';
import colors from 'src/theme/colors';
import { Button, Flex, Text } from '@chakra-ui/react';
import Plus from 'src/assets/icons/Plus';
import { useUserCache } from 'src/apollo/cache';
import assert from 'src/utils/assert';
import Show from './Show';
import { useMyShowsContext } from './MyShowsContext';

export default function MyShowsList() {
  const { setContentType, onSwitchToShow, onOpen, isMyAccount } = useMyShowsContext();
  const user = useUserCache();
  assert(user, 'user not found in cache');
  const { data } = useShowsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: user.id,
    },
  });

  return (
    <>
      {data?.shows.map(show => (
        <Show key={show.id} show={show} onSwitchToShow={() => onSwitchToShow(show.id)} />
      ))}
      <Button
        aria-label="add new show"
        variant="empty"
        marginTop="6px"
        justifyContent="flex-start"
        onClick={() => {
          setContentType('add-new-show');
          // in my account, the popup is not yet open so we need to open it
          if (isMyAccount) {
            onOpen();
          }
        }}
      >
        <Flex
          borderRadius="8px"
          minWidth="36px"
          height="36px"
          bg={colors.podcastPurple100}
          marginRight="14px"
          alignItems="center"
          justifyContent="center"
        >
          <Plus fill={colors.podcastPurple} />
        </Flex>
        <Text color={colors.podcastShade600}>Add new show</Text>
      </Button>
    </>
  );
}
