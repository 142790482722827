/**
 * Remove property with specified key name from deeply nested object
 */

interface Object {
  [key: string]: any;
}

export default function omitDeep(obj: Object, key: string) {
  const keys = Object.keys(obj);
  const newObj: Object = {};
  keys.forEach(i => {
    if (i === key) return;
    const val = obj[i];
    if (Array.isArray(val)) {
      newObj[i] = omitDeepArray(val, key);
    } else if (typeof val === 'object' && val !== null) {
      newObj[i] = omitDeep(val, key);
    } else {
      newObj[i] = val;
    }
  });
  return newObj;
}

function omitDeepArray(arr: string[], key: string): {}[] {
  return arr.map(val => {
    if (Array.isArray(val)) {
      return omitDeepArray(val, key);
    }
    if (typeof val === 'object') {
      return omitDeep(val, key);
    }
    return val;
  });
}
