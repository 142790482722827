import { Alert, Box } from '@chakra-ui/react';
import React from 'react';
import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';

export function WarningAlert({
  warning,
  hideCarefulText = false,
}: {
  warning: string;
  hideCarefulText?: boolean;
}) {
  return (
    <Alert
      border={`1px solid ${colors.podcastShade300}`}
      bg={colors.podcastYellow100}
      color={colors.podcastYellow800}
      mt="12px"
      py="9px"
      px="20px"
      borderRadius="6px"
      fontSize="13px"
      display="block"
    >
      {!hideCarefulText && (
        <>
          <Box as="span" fontFamily={fonts.bold}>
            Careful!
          </Box>{' '}
        </>
      )}
      {warning}
    </Alert>
  );
}
