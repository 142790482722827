import { ToastId } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAlituToast from 'src/components/toast/useAlituToast';

function useIsOnline() {
  const [isOnline, setOnline] = useState<boolean>();

  useEffect(() => {
    window.addEventListener('online', () => setOnline(true));
    window.addEventListener('offline', () => setOnline(false));

    return () => {
      window.removeEventListener('online', () => setOnline(true));
      window.removeEventListener('offline', () => setOnline(false));
    };
  }, []);

  return isOnline;
}

export default function useNetworkNotifications() {
  const isOnline = useIsOnline();
  const toast = useAlituToast();
  const [offlineToastId, setOfflineToastId] = useState<ToastId | null>();

  useEffect(() => {
    if (isOnline === false && !offlineToastId) {
      // render a notification when the user loses internet connection
      const id = toast.render({ title: 'You are not connected to the internet', type: 'error' });
      setOfflineToastId(id);
    }
  }, [isOnline, offlineToastId, toast]);

  useEffect(() => {
    if (isOnline === true && offlineToastId) {
      // cancel offline notification and notify user they're back online
      toast.close(offlineToastId);
      setOfflineToastId(null);
      toast.render({ title: `You're back online` });
    }
  }, [isOnline, offlineToastId, toast, setOfflineToastId]);
}
