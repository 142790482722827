import { ApolloError } from '@apollo/client';

/**
 *
 * @param error - error response from Apollo query or mutation
 * @description trigger error fallback component on Apollo error
 * @example
 * * const [, { error }] = useUserQuery();
 * useApolloErrorHandler(error);
 */

export default function useApolloErrorHandler(error?: ApolloError) {
  const splitErrorMessage = error?.message.split('Error:');
  let errorMessage = error?.message;
  if (splitErrorMessage && splitErrorMessage?.length > 1) {
    errorMessage = splitErrorMessage?.[splitErrorMessage.length - 1].trim();
  }

  if (error) {
    if (errorMessage) error.message = errorMessage;
    throw error;
  }
}
