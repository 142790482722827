import { ReactNode } from 'react';
import { FormErrorMessage } from '@chakra-ui/react';
import WarningTriangleIcon from '../../assets/icons/WarningTriangleIcon';

interface FieldErrorMessageProps {
  showIcon?: boolean;
  children: ReactNode;
}

export default function FieldErrorMessage({ children, showIcon = true }: FieldErrorMessageProps) {
  return (
    <FormErrorMessage>
      {children}
      {showIcon && <WarningTriangleIcon />}
    </FormErrorMessage>
  );
}
