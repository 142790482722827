/* eslint-disable import/no-import-module-exports */
import 'core-js';
import { createRoot } from 'react-dom/client';
import { configure } from 'react-hotkeys';
import 'regenerator-runtime';
import AppProviders from './core/AppProviders.tsx';
import App from './core/App.tsx';

configure({
  ignoreRepeatedEventsWhenKeyHeldDown: false,
});

// React hot loader
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === '') {
  module.hot?.accept();
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <AppProviders>
    <App />
  </AppProviders>,
);
