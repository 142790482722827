import { Box, BoxProps, CenterProps } from '@chakra-ui/react';
import Avatar from 'react-avatar';
import { useUserCache } from 'src/apollo/cache';
import colors from 'src/theme/colors';
import hexToRgb from 'src/utils/hexToRgb';
import { colours, fonts } from 'src/styles/variables';
import { useMenuContext } from '../../MenuContext';

export default function MenuAvatar() {
  const user = useUserCache();
  const [menuState] = useMenuContext();

  return (
    <Box {...(menuState === 'closed' && { cursor: 'pointer' })} {...styles.container}>
      <Avatar
        email={user?.email}
        name={`${user?.firstName} ${user?.lastName}`}
        size="30px"
        color={`linear-gradient(180deg, ${colors.podcastShade100} 22.4%, ${colors.AlituPaleLavender} 100%)`}
        fgColor={colors.podcastPurple}
        round
      />
    </Box>
  );
}

const styles: { container: BoxProps; hoverLogoutButton: CenterProps } = {
  container: {
    position: 'relative',
    width: '38px',
    height: '38px',
    border: `4px solid ${colours.white}`,
    borderRadius: '100%',
    sx: {
      '.sb-avatar': {},
      '.sb-avatar__text': {
        fontFamily: fonts.bold,
        textShadow:
          '0px 1px 0px rgba(255, 255, 255, 0.35), 0px 1.5px 1px rgba(255, 255, 255, 0.15)',
        fontSize: '15px',
        color: colors.podcastRed,
      },
    },
  },
  hoverLogoutButton: {
    position: 'absolute',
    w: '100%',
    h: '100%',
    bg: hexToRgb(colours.black, 0.5),
    borderRadius: 'full',
  },
};
