export default {
  baseStyle: {
    color: 'podcastPurple',
    textDecoration: 'underline',
    fontWeight: 'normal !important',
    _hover: {
      color: 'podcastPurple',
    },

    _visited: {
      color: 'podcastPurple800',
    },
  },
  variants: {
    grey: {
      color: 'podcastShade600',
      _hover: {
        color: 'podcastShade700',
      },
      _visited: {
        color: 'podcastShade500',
      },
    },
  },
};
