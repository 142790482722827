export default {
  baseStyle: {
    opacity: 1,
    backgroundColor: 'podcastShade200',
  },
  variants: {
    signUp: {
      height: '2px',
      marginTop: '7px',
      opacity: 1,
      backgroundColor: 'podcastShade100',
      marginBottom: '10px',
    },
    alituWavesHeader: {
      borderColor: 'podcastPurple800',
      height: '18px',
      width: '2px',
      opacity: 0.2,
      backgroundColor: 'podcastPurple800',
    },
  },
};
