import { colours } from 'src/styles/variables';
import hexToRgb from 'src/utils/hexToRgb';
import colors from '../colors';

const Popover = {
  baseStyle: {
    content: {
      bg: hexToRgb(colors.podcastShade700, 0.95),
      '--popper-arrow-bg': colors.podcastShade700,
      color: colours.white,
    },
    body: {},
    header: {
      borderBottomColor: 'podcastShade500',
      borderBottomWidth: '2px',
    },
  },
};

export default Popover;
