/**
 * Utility function to format a date into a pretty string representation.
 *
 * @remarks
 * This function takes a date string and formats it into a human-readable format.
 * It supports options to include time, use short date format, and handle Unix timestamps.
 *
 * @param options.date - The date string to format.
 * @param options.withTime - Whether to include the time in the formatted string. Default is false.
 * @param options.shortDate - Whether to use the short date format. Default is false.
 * @param options.isUnixTimestamp - Whether the date is a Unix timestamp. Default is false.
 *
 * @returns The formatted date string, e.g. December 5th, 2023, or Dec 5th, 2023 if shortDate is true
 */

const getPrettyDate = ({
  date: passedDate,
  withTime = false,
  isUnixTimestamp = false,
  shortDate = false,
}: {
  date: string | null | undefined;
  withTime?: boolean;
  shortDate?: boolean;
  isUnixTimestamp?: boolean;
}) => {
  if (!passedDate) return '';

  let validatedDate = passedDate;

  if (Date.parse(passedDate)) {
    validatedDate = Date.parse(passedDate).toString();
  }
  const date = new Date(
    isUnixTimestamp ? parseInt(validatedDate, 10) * 1000 : parseInt(validatedDate, 10),
  );

  return dateFormatter(date, withTime, shortDate);
};

function pad(value: string): string {
  if (Number(value) < 10) {
    return `0${value}`;
  }
  return value.toString();
}

function getOrdinalDay(day: number): string {
  const stringDate = day.toString();
  if (day > 3 && day < 21) {
    return `${stringDate}th`;
  }
  switch (day % 10) {
    case 1:
      return `${stringDate}st`;
    case 2:
      return `${stringDate}nd`;
    case 3:
      return `${stringDate}rd`;
    default:
      return `${stringDate}th`;
  }
}

function dateFormatter(date: Date, withTime: boolean, shortDate: boolean): string {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
  const shortMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const hour = new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(date);
  const minute = pad(new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(date));
  const formattedTime = `${hour}:${minute}`;

  // Takes into account if the date is short or long and whether or not time is included
  return `${shortDate ? shortMonth : month} ${getOrdinalDay(Number(day))}, ${year}${
    withTime ? ` ${formattedTime}` : ''
  }`;
}

export default getPrettyDate;
