import { Grid, Box, Heading } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'src/components/error-fallback/ErrorFallbackModal';
import { useUserCache } from 'src/apollo/cache';
import { Navigate } from 'react-router-dom';
import SidebarMenu from 'src/components/menu/sidebar/SidebarMenu';
import AdminNav from 'src/components/admin/AdminNav';
import { FC } from 'react';

interface AdminRouteProps {
  component: FC;
}

export default function AdminRoute({ component: Component, ...props }: AdminRouteProps) {
  const user = useUserCache();

  if (!user?.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const hasAccessToAdmin = ['admin', 'editor'].includes(user.role);
  if (!hasAccessToAdmin) return <Navigate to="/" />;

  return (
    <Grid templateColumns="min-content auto">
      <SidebarMenu />
      <Box width="90%" margin="50px auto 0">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Heading size="h1">Admin Panel</Heading>
          {user.role === 'admin' && <AdminNav />}
          <Component {...props} />
        </ErrorBoundary>
      </Box>
    </Grid>
  );
}
