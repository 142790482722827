import { Box, BoxProps, Center, Flex, Image } from '@chakra-ui/react';
import logoWhiteWithText from 'src/assets/icons/logos/logoWhiteWithText.svg';
import colors from 'src/theme/colors';

function TopBar({ children, ...otherProps }: BoxProps) {
  return (
    <Box
      h={['66px', '78px']}
      background={colors.podcastPurple}
      boxShadow={[
        '0px 0px 18px rgba(77, 59, 187, 0.34)',
        '0px 0px 24px rgba(77, 59, 187, 0.34)',
        null,
        '0px 0px 36px rgba(77, 59, 187, 0.34)',
      ]}
      minW="320px"
      position="relative"
      {...otherProps}
    >
      <Center h="100%" px={['15px', 0]}>
        <Flex
          justifyContent={children ? 'space-between' : 'space-around'}
          alignItems="center"
          w={['100%', 510, 710, 950, 1140]}
          minW="250px"
        >
          <a href="https://alitu.com/" aria-label="Alitu web page">
            <Image
              src={logoWhiteWithText}
              w={[120, 140]}
              h={[36, 42]}
              _hover={{ opacity: 0.9 }}
              _active={{ opacity: 0.8 }}
              alt="Alitu the podcast maker logo"
            />
          </a>
          {children}
        </Flex>
      </Center>
    </Box>
  );
}

export default TopBar;
