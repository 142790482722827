import {
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Text,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  Tooltip,
  FormHelperTextProps,
} from '@chakra-ui/react';
import { ComponentProps, forwardRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from 'src/assets/icons/InfoIcon';
import Dropdown from 'src/components/input/Dropdown';
import { colours, fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import { APPLE_PODCAST_CATEGORIES } from './const';

type FormContainerRef = HTMLDivElement;
type FormContainerProps = FlexProps & {
  heading?: string;
  subHeading?: string;
  id?: string;
  urlHash?: `#${string}` | '';
  hideDivider?: boolean;
};

export const FormContainer = forwardRef<FormContainerRef, FormContainerProps>(
  ({ heading, subHeading, id, urlHash, hideDivider, ...props }, ref) => {
    const { hash, pathname } = useLocation();

    // if the url hash is not the same as the current hash, don't render the component
    // but only on settings page as that's the only place with tabbed navigation that uses hash
    if (pathname.includes('settings') && hash && hash !== urlHash) {
      return null;
    }

    return (
      <Flex
        as="section"
        direction="column"
        p="33px 50px"
        bg={colours.white}
        boxShadow={`inset 0 0 0 2px ${colors.podcastShade200}`}
        borderRadius="12px"
        id={id}
        ref={ref}
        {...props}
      >
        {(heading || subHeading) && (
          <>
            <SectionHeader heading={heading} subHeading={subHeading} />
            {!hideDivider && <Divider mb="1.8125rem" />}
          </>
        )}
        {props.children}
      </Flex>
    );
  },
);

type InputRef = HTMLInputElement;
type InputProps = ChakraInputProps & {
  label: string;
  id: string;
  helperText?: string;
  errorMessage?: string;
  isOptional?: boolean;
  tooltipLabel?: string;
};

function SectionHeader({ heading, subHeading }: { heading?: string; subHeading?: string }) {
  return (
    <Box m="0 0 18px">
      {heading && <Heading variant="sectionHeader">{heading}</Heading>}
      {subHeading && (
        <Text as="h6" w="max-content" p={0} color="podcastShade500">
          {subHeading}
        </Text>
      )}
    </Box>
  );
}
export const Input = forwardRef<InputRef, InputProps>(
  ({ label, id, helperText, errorMessage, isOptional, tooltipLabel, ...props }, ref) => (
    <FormControl id={id} isInvalid={props.isInvalid}>
      <Flex alignItems="center">
        <FormLabel display="flex">
          {label}
          {isOptional && (
            <Text ml="5px" fontFamily={fonts.bold}>
              (optional)
            </Text>
          )}
        </FormLabel>
        {tooltipLabel && (
          <Tooltip w="230px" label={tooltipLabel}>
            <Box>
              <InfoIcon mb="7px" />
            </Box>
          </Tooltip>
        )}
      </Flex>
      <ChakraInput type="text" ref={ref} {...props} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  ),
);

type TextareaRef = HTMLTextAreaElement;
type TextareaProps = ChakraTextareaProps & {
  label: string;
  id: string;
  helperText?: string;
  errorMessage?: string;
};
export const Textarea = forwardRef<TextareaRef, TextareaProps>(
  ({ id, label, helperText, errorMessage, ...props }, ref) => (
    <FormControl id={id} isInvalid={props.isInvalid}>
      <FormLabel>{label}</FormLabel>
      <ChakraTextarea ref={ref} {...props} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  ),
);

type DropdownRef = HTMLSelectElement;
type PodcastCategoryDropdownProps = Omit<ComponentProps<typeof Dropdown>, 'children'>;
export const PodcastCategoryDropdown = forwardRef<DropdownRef, PodcastCategoryDropdownProps>(
  (props, ref) => (
    <Dropdown {...props} ref={ref} labelVariant="large" helperProps={formHelperTextProps}>
      {APPLE_PODCAST_CATEGORIES.map(category => (
        <Fragment key={category}>
          <option>{category}</option>
        </Fragment>
      ))}
    </Dropdown>
  ),
);

type CheckboxRef = HTMLInputElement;
type CheckboxProps = ChakraCheckboxProps & {
  label?: string;
  id: string;
  helperText?: string;
  isOptional?: boolean;
  tooltipText?: string;
};
export const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  ({ id, label, helperText, isOptional, tooltipText, ...props }, ref) => (
    <FormControl
      id={id}
      {...(tooltipText && {
        w: 'auto',
        display: 'flex',
        alignItems: 'flex-end',
      })}
    >
      <FormLabel display="flex">
        {label}
        {isOptional && (
          <Text ml="5px" fontFamily={fonts.bold}>
            (optional)
          </Text>
        )}
      </FormLabel>
      <ChakraCheckbox
        ref={ref}
        color="podcastShade500"
        fontFamily={fonts.normal}
        fontSize="1rem"
        lineHeight="1.125rem"
        {...props}
      />
      {tooltipText && (
        <Tooltip w="300px" label={tooltipText}>
          <Box ml="10px">
            <InfoIcon mb="7px" />
          </Box>
        </Tooltip>
      )}
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  ),
);

export const formHelperTextProps: FormHelperTextProps = {
  color: 'podcastShade400',
  bg: 'none',
  border: 'none',
  p: 0,
  m: 0,
  h: '1.375rem',
};
