import { useDisclosure } from '@chakra-ui/react';
import { createContext, useContext, useReducer, useCallback, useMemo, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserCache } from 'src/apollo/cache';
import { updateUserCache } from 'src/apollo/cache/update';
import { Podcast, useUpdateUserMutation } from 'src/generated/graphql';
import assert from 'src/utils/assert';

interface State {
  contentType:
    | 'list-shows'
    | 'remove-show'
    | 'add-new-show'
    | 'renew-show'
    | 'show-added-confirmation'
    | 'show-removed-confirmation'
    | 'show-renewed-confirmation';
  showToRemove: Podcast['id'] | null;
  showToRenew: Podcast['id'] | null;
  isMyAccount: boolean;
  isOpen: boolean;
}

type Actions =
  | {
      type: 'setContentType';
      payload: State['contentType'];
    }
  | {
      type: 'setShowToRemove';
      payload: State['showToRemove'];
    }
  | {
      type: 'setShowToRenew';
      payload: State['showToRenew'];
    }
  | {
      type: 'setIsMyAccount';
      payload: State['isMyAccount'];
    };

type Context = State & {
  setContentType: (type: State['contentType']) => void;
  setShowToRemove: (id: Podcast['id'] | null) => void;
  setShowToRenew: (id: Podcast['id']) => void;
  onSwitchToShow: (id: string, isAddingNewShow?: boolean) => void;
  setIsMyAccount: (isMyAccount: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
};

const initialState: State = {
  contentType: 'list-shows',
  showToRemove: null,
  showToRenew: null,
  isMyAccount: false,
  isOpen: false,
};

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case 'setContentType':
      return { ...state, contentType: action.payload };
    case 'setShowToRemove':
      return { ...state, showToRemove: action.payload };
    case 'setShowToRenew':
      return { ...state, showToRenew: action.payload };
    case 'setIsMyAccount':
      return { ...state, isMyAccount: action.payload };
  }
}

const MyShowsContext = createContext<Context | undefined>(undefined);

export function useMyShowsContext() {
  const context = useContext(MyShowsContext);
  if (context === undefined) {
    throw new Error('MyShows context must be wrapped in MyShowsContextProvider');
  }
  return context;
}

export function MyShowsContextProvider({
  children,
}: {
  children: ReactElement<any> | ReactElement[];
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useUserCache();
  const navigate = useNavigate();
  const [updateUser, { client }] = useUpdateUserMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSwitchToShow = useCallback(
    (id: string, isAddingNewShow?: boolean) => {
      assert(user, 'user not found in cache');
      updateUser({
        variables: { userId: user.id, activeProjectId: id },
        onCompleted: res => {
          updateUserCache({ user: res.updateUser, client });
          if (isAddingNewShow) {
            dispatch({ type: 'setContentType', payload: 'show-added-confirmation' });
          }
          navigate('/');
        },
      });
    },
    [user, updateUser, client, navigate],
  );

  const value = useMemo(
    () => ({
      ...state,
      setContentType: (type: State['contentType']) =>
        dispatch({ type: 'setContentType', payload: type }),
      setShowToRemove: (id: Podcast['id'] | null) =>
        dispatch({ type: 'setShowToRemove', payload: id }),
      setShowToRenew: (id: Podcast['id'] | null) =>
        dispatch({ type: 'setShowToRenew', payload: id }),
      setIsMyAccount: (isMyAccount: boolean) =>
        dispatch({ type: 'setIsMyAccount', payload: isMyAccount }),
      onSwitchToShow,
      onOpen,
      onClose,
      isOpen,
    }),
    [state, onSwitchToShow, isOpen, onOpen, onClose],
  );

  return <MyShowsContext.Provider value={value}>{children}</MyShowsContext.Provider>;
}
