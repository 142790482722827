import { extendTheme } from '@chakra-ui/react';
import { fonts } from 'src/styles/variables';
import colors from './colors';
import Button from './components/Button';
import Heading from './components/Heading';
import Input from './components/Input';
import Textarea from './components/Textarea';
import NumberInput from './components/NumberInput';
import FormLabel from './components/FormLabel';
import Form from './components/Form';
import FormError from './components/FormError';
import Checkbox from './components/Checkbox';
import Radio from './components/Radio';
import Switch from './components/Switch';
import Select from './components/Select';
import Table from './components/Table';
import Link from './components/Link';
import Text from './components/Text';
import Modal from './components/Modal';
import Popover from './components/Popover';
import Tooltip from './components/Tooltip';
import Divider from './components/Divider';
import Progress from './components/Progress';

const overrides = {
  styles: {
    global: {
      html: {
        WebkitFontSmoothing: 'auto',
        MozOsxFontSmoothing: 'auto',
        height: '100%',
        overflow: 'hidden',
      },
      body: {
        height: '100%',
        overflowX: 'hidden',
        margin: 0,
      },
      '#app': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'auto',
      },
      ul: {
        listStyleType: 'none',
      },
      b: {
        fontFamily: fonts.bold,
      },
    },
  },
  fonts: {
    heading: fonts.normal,
    body: fonts.normal,
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '1100px',
    xl: '1390px',
    '2xl': '1500px',
  },
  colors,
  components: {
    Button,
    Input,
    Textarea,
    NumberInput,
    FormLabel,
    FormError,
    Form,
    Checkbox,
    Radio,
    Switch,
    Select,
    Table,
    Link,
    Heading,
    Text,
    Modal,
    Popover,
    Tooltip,
    Divider,
    Progress,
  },
};

export default extendTheme(overrides);
