import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  UseToastOptions,
} from '@chakra-ui/react';
import colors from 'src/theme/colors';
import { colours, fonts } from 'src/styles/variables';
import TickIconFilled from 'src/assets/icons/TickIconFilled';
import React from 'react';

export interface ToastProps {
  title: string;
  description?: UseToastOptions['description'];
  type?: string;
  action?: {
    text: string;
    onClick: () => void;
  };
  onClose: () => void;
}

/**
 * @description
 * A notification component which can be used throughout Alitu. This is designed to be used with the useAlituToast hook.
 */
export default function Toast({ onClose, title, description, type, action }: ToastProps) {
  return (
    <Flex
      background="rgba(41, 40, 61, 0.9)"
      boxShadow="0px 3px 10px rgba(0, 0, 0, 0.05)"
      borderRadius="12px"
      justifyContent="space-between"
      p="15px 15px 14px 20px"
      minW="315px"
      w="330px"
    >
      <Flex gridGap="12px">
        {(() => {
          switch (type) {
            case 'loading':
              return;
            case 'error':
              return <ErrorIcon />;
            case 'success':
            default:
              return <TickIconFilled />;
          }
        })()}
        <Box>
          <Heading variant="toastTitle">
            {title}
            {action && (
              <>
                {' '}
                <Button
                  variant="link"
                  color={colours.white}
                  textDecoration="underline"
                  onClick={() => {
                    action.onClick();
                    onClose();
                  }}
                >
                  {action.text}
                </Button>
              </>
            )}
          </Heading>
          {description && (
            <Text
              color="podcastShade200"
              fontSize="14px"
              lineHeight="18px"
              mt="7px"
              fontFamily={fonts.bold}
              wordBreak="break-word"
            >
              {description}
            </Text>
          )}
        </Box>
      </Flex>
      <IconButton
        onClick={onClose}
        variant="empty"
        aria-label="Close notification"
        h="min-content"
        minW="min-content"
      >
        <CloseIcon />
      </IconButton>
    </Flex>
  );
}

function ErrorIcon() {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="url(#paint0_linear_1667_2355)" />
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke={colours.white}
        strokeOpacity="0.1"
      />
      <path
        d="M7 7L13 13"
        stroke={colours.white}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7L7 13"
        stroke={colours.white}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1667_2355"
          x1="10"
          y1="20"
          x2="10"
          y2="-3.72529e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.podcastRed600} />
          <stop offset="1" stopColor={colors.podcastRed400} />
        </linearGradient>
      </defs>
    </Icon>
  );
}

function CloseIcon() {
  return (
    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m1 1 8 8m0-8L1 9"
        stroke={colors.podcastShade300}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
