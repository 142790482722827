import { useSubscriptionCache, useUserCache } from 'src/apollo/cache';
import useCheckEU from 'src/pages/signup/hooks/useCheckEU';
import useFetchCost from 'src/pages/signup/hooks/useFetchCost';
import { Plan } from 'src/pages/signup/types';
import assert from 'src/utils/assert';

export default function useGetExtraShowCost() {
  const user = useUserCache();
  const subscription = useSubscriptionCache();
  assert(subscription?.plans, 'subscription or subscription plans not found in cache');
  assert(user, 'user not found in cache');
  const interval = subscription.plans[0].interval as 'month' | 'year';
  const countryCode = subscription.metadata?.countryCode || 'US';
  const isEuropeanUnion = useCheckEU(subscription.metadata?.countryCode || 'US');
  const { cost, currencySymbol } = useFetchCost({
    plan: `${interval}ly_extra_shows` as Plan,
    countryCode,
    isEuropeanUnion,
  });

  const { cost: originalCost } = useFetchCost({
    plan: `${interval}ly` as Plan,
    countryCode,
    isEuropeanUnion,
  });

  return {
    originalCost,
    cost,
    currencySymbol,
  };
}
