import { Box, Center, Spinner, Grid } from '@chakra-ui/react';
import { useMenuContext } from 'src/components/menu/MenuContext.tsx';
import SidebarMenu from 'src/components/menu/sidebar/SidebarMenu.tsx';
import { Helmet } from 'react-helmet';
import TopBar from 'src/components/top-bar/TopBar';
import colors from 'src/theme/colors';

export function FallbackAuth() {
  const [menuState] = useMenuContext();

  return (
    <Grid gridTemplateColumns={`${menuState === 'open' ? '185px' : '5%'} auto`}>
      <SidebarMenu />
    </Grid>
  );
}

export function FallbackUnauth() {
  return (
    <>
      <Helmet>
        <style>{`body { background-color: ${colors.podcastShade100}; }`}</style>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <TopBar>
        <Box />
      </TopBar>
      <Center h="50%">
        <Spinner size="xl" color="podcastShade300" />
      </Center>
    </>
  );
}
