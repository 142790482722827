import { useEffect, useState } from 'react';
import { useCountriesQuery } from 'src/generated/graphql';

function useCheckEU(countryCode: string) {
  const [isEu, setIsEu] = useState(false);
  const countries = useCountriesQuery().data?.countries;

  useEffect(() => {
    const countryObj = countries?.find(country => country.alpha2Code === countryCode);
    let isEuropeanUnion = false;
    if (countryObj) {
      isEuropeanUnion =
        countryObj.regionalBlocs?.some(regionalBloc => regionalBloc?.name === 'European Union') ??
        false;
    }
    setIsEu(isEuropeanUnion);
  }, [countries, countryCode, setIsEu]);
  return isEu;
}

export default useCheckEU;
