import {
  Box,
  Button,
  Flex,
  Heading,
  Modal as ChakraModal,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntercom } from 'react-use-intercom';
import RobotIcon from 'src/assets/icons/RobotIcon';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  heading?: string;
  Body: ReactNode;
  Footer?: ReactNode;
  width?: string;
  subheading?: string;
  onTryAgain?: () => void;
  errorMessage?: string;
  tryAgainButtonText?: string;
}

export default function ErrorModal({
  isOpen,
  onClose,
  heading,
  subheading,
  Body: BodyComp,
  Footer: FooterComp,
  width,
  onTryAgain,
  errorMessage,
  tryAgainButtonText,
}: ModalProps) {
  const { showNewMessage: sendIntercomMessage } = useIntercom();
  const intercomMessageTemplate = errorMessage
    ? `Help! I got an error message that says "${errorMessage}"`
    : '';
  return (
    <Container isOpen={isOpen} onClose={onClose}>
      <Content width={width || '360px'}>
        <Header heading={heading || 'Something went wrong...'} subheading={subheading} />
        <Body>{BodyComp}</Body>
        <Footer>
          {FooterComp ?? (
            <Flex>
              <Button h="42px" mr="8px" onClick={onTryAgain || onClose}>
                {tryAgainButtonText || 'Try again'}
              </Button>
              <Button
                variant="tertiary"
                h="42px"
                onClick={() => {
                  sendIntercomMessage(intercomMessageTemplate);
                }}
              >
                Contact Support
              </Button>
            </Flex>
          )}
        </Footer>
      </Content>
    </Container>
  );
}

function Container(props: ChakraModalProps) {
  return (
    <ChakraModal isCentered scrollBehavior="inside" trapFocus={false} {...props}>
      <ModalOverlay />
      {props.children}
    </ChakraModal>
  );
}

function Content(props: ModalContentProps) {
  return <ModalContent w={props.width || '600px'} maxH="95vh" maxW="95vw" {...props} />;
}

function Header({
  heading,
  subheading,
  ...props
}: ModalHeaderProps & { heading: string; subheading?: string }) {
  return (
    <Box mt="30px" mx="30px" {...props}>
      <RobotIcon />
      <ModalCloseButton h="42px" w="42px" borderRadius="8px" right="25px" top="27px" />
      <Heading color="podcastShade600" size="h5" my="8px">
        {heading}
      </Heading>
      {subheading && (
        <Text textAlign="start" mt="8px">
          {subheading}
        </Text>
      )}
    </Box>
  );
}

function Body(props: ModalBodyProps) {
  return <Box px="30px" {...props} />;
}

function Footer(props: ModalFooterProps) {
  return <Box p="18px 8px 30px 30px" {...props} />;
}
