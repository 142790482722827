import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  Text,
  LayoutProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import RightArrow from 'src/assets/icons/RightArrow';
import { ErrorAlert } from 'src/components/input/ErrorAlert';
import { WarningAlert } from 'src/components/input/WarningAlert';
import AttentionBox from 'src/pages/signup/AttentionBox';
import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  Body: ReactNode;
  width?: LayoutProps['width'];
  subheading?: string;
  footerButtons?: FooterButtonProps[];
  error?: string;
  warning?: string;
  isBeta?: boolean;
  closeOnOverlayClick?: boolean;
  modalContentProps?: BoxProps;
}

interface FooterButtonProps {
  key: string;
  variant?: string;
  text: string;
  onClick?: () => void;
  hasArrow?: boolean;
  leftIcon?: JSX.Element;
  buttonProps?: ButtonProps;
}

export default function Modal({
  isOpen,
  onClose,
  heading,
  subheading,
  Body: BodyComp,
  footerButtons,
  width,
  error,
  warning,
  isBeta,
  closeOnOverlayClick = true,
  modalContentProps,
  ...props
}: ModalProps & BoxProps) {
  return (
    <Container isOpen={isOpen} onClose={onClose} closeOnOverlayClick={closeOnOverlayClick}>
      <Content width={width} maxW="1040px" {...modalContentProps}>
        <Header heading={heading} subheading={subheading} isBeta={isBeta} />
        <Body {...props} pt="30">
          {BodyComp}
        </Body>
        {footerButtons && <Footer buttons={footerButtons} error={error} warning={warning} />}
      </Content>
    </Container>
  );
}

export function Container(props: ChakraModalProps) {
  return (
    <ChakraModal trapFocus={false} isCentered scrollBehavior="inside" {...props}>
      <ModalOverlay />
      {props.children}
    </ChakraModal>
  );
}

export function Content(props: ModalContentProps) {
  return <ModalContent w={props.width || '600px'} maxH="95vh" maxW="95vw" {...props} />;
}

export function Header({
  heading,
  subheading,
  isBeta = false,
  ...props
}: ModalHeaderProps & { heading: string; subheading?: string; isBeta?: boolean }) {
  return (
    <ModalHeader
      borderBottom={`2px solid ${colors.podcastShade200}`}
      display="flex"
      justifyContent="space-between"
      {...props}
    >
      <Box>
        <Heading as="h3" variant="modalTitle">
          {heading}
          {isBeta && (
            <AttentionBox ml="7px" p="3px" verticalAlign="middle">
              BETA
            </AttentionBox>
          )}
        </Heading>
        {subheading && (
          <Text variant="lighter" fontFamily={fonts.normal} textAlign="start" mt="3px">
            {subheading}
          </Text>
        )}
      </Box>
      <ModalCloseButton h="42px" w="42px" borderRadius="8px" position="static" />
    </ModalHeader>
  );
}

export function Body(props: ModalBodyProps) {
  return <ModalBody px="40px" pb="30px" overflowY="auto" {...props} />;
}

export function Footer({
  buttons,
  error,
  warning,
  ...props
}: {
  buttons: FooterButtonProps[];
  error?: string;
  warning?: string;
}) {
  return (
    <ModalFooter bg="podcastShade100" p="30px 40px" {...props}>
      <Box justifyContent="space-between" w="100%">
        {buttons?.map(({ variant, text, onClick, hasArrow, leftIcon, buttonProps, key }, index) => {
          return (
            <Button
              key={key}
              variant={variant || (index === 0 ? 'primary' : 'tertiary')}
              onClick={onClick}
              w="100%"
              mt={index > 0 ? '12px' : '0'}
              rightIcon={hasArrow ? <RightArrow /> : undefined}
              leftIcon={leftIcon}
              {...buttonProps}
            >
              {text}
            </Button>
          );
        })}
        {error && <ErrorAlert>{error}</ErrorAlert>}
        {warning && <WarningAlert warning={warning} />}
      </Box>
    </ModalFooter>
  );
}
