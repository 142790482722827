import { IconButton } from '@chakra-ui/react';
import SmallTooltip from 'src/pages/episodes/episode-box/components/buttons/SmallTooltip';
import { colours } from 'src/styles/variables';
import hexToRgb from 'src/utils/hexToRgb';
import { useMenuContext } from '../../MenuContext';

export default function OpenCloseMenuButton({ addAnimation }: { addAnimation: () => void }) {
  const [menuState, setMenuState] = useMenuContext();

  function onButtonClick() {
    addAnimation();
    setMenuState(menuState === 'open' ? 'closed' : 'open');
  }

  return (
    <SmallTooltip
      label={menuState === 'open' ? 'Collapse Navigation?' : 'Open Navigation?'}
      placement="right"
      padding="6px 10px"
    >
      <IconButton
        aria-label={menuState === 'open' ? 'close menu' : 'open menu'}
        onClick={onButtonClick}
        borderRadius="8px"
        bgGradient="none"
        boxShadow="none"
        bgColor={
          menuState === 'closed' ? hexToRgb(colours.white, 0.2) : hexToRgb(colours.black, 0.2)
        }
        alignSelf="center"
        maxW="36px"
        minW="36px"
        h="36px"
        p="0"
      >
        <svg
          width="14"
          height="12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: `scaleX(${menuState === 'open' ? -1 : 1})`,
            transitionDuration: '0.3s',
          }}
        >
          <path
            d="M8 1l5 5-5 5M1 1l5 5-5 5"
            stroke={colours.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </IconButton>
    </SmallTooltip>
  );
}
