import { inputAnatomy } from '@chakra-ui/anatomy';
import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const $height = cssVar('input-height');
const $padding = cssVar('input-padding');

const baseStyle = definePartsStyle({
  field: {
    color: 'podcastShade500',
    fontFamily: fonts.normal,
    boxShadow: 'inset 0px -3px 10px rgba(205, 204, 227, 0.2)',
    borderRadius: 12,
    _placeholder: {
      color: 'podcastShade500',
      opacity: '0.5',
    },
  },
});

const primary = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'podcastShade300',
    borderRadius: '12px',
    outlineOffset: 0,
    _hover: {
      boxShadow: 'none',
    },
    _focusWithin: {
      borderColor: 'podcastPurple',
      outlineWidth: '1px',
      outlineStyle: 'solid',
      outlineColor: 'podcastPurple',
      _invalid: {
        borderColor: 'AlituPink',
        outlineWidth: '1px',
        outlineColor: 'AlituPink',
      },
    },
    _invalid: {
      border: `1px solid ${colors.AlituPink}`,
      boxShadow: 'inset 0px -3px 6px rgba(239, 57, 113, 0.05)',
    },
  },
});

const labelInsideInput = definePartsStyle({
  field: {
    position: 'absolute',
    boxShadow: 'none',
    borderRadius: 0,
    background: 'none',
    top: '26px',
    p: 0,
    w: 'calc(100% - 40px)',
    ml: '20px',
    h: '30px',
  },
});

const small = defineStyle({
  [$height.variable]: '36px',
  fontSize: '13px',
});

const large = defineStyle({
  [$height.variable]: '48px',
  [$padding.variable]: '20px',
  fontSize: '16px',
});

const sizes = {
  small: definePartsStyle({ field: small, element: small, addon: small, group: small }),
  large: definePartsStyle({ field: large, element: large, addon: large, group: large }),
};

export default defineMultiStyleConfig({
  baseStyle,
  variants: { primary, labelInsideInput },
  defaultProps: { variant: 'primary', size: 'large' },
  sizes,
});
