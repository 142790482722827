import { createContext, Dispatch, ReactChild, useContext, useReducer } from 'react';
import { Job } from 'src/generated/graphql';

interface EpisodesPageState {
  playingFileId?: string;
  isTrialWarningModalOpen: boolean;
  loading: boolean;
  filter: { name: string; sort: string; type?: Job['status']; isDraft?: boolean };
  pagination: {
    totalPages: number;
    offset: number;
    limit: number;
    height: number;
  };
}

type EpisodeContextType = [EpisodesPageState, Dispatch<EpisodesPageReducerAction>];

const EpisodesPageContext = createContext<EpisodeContextType | undefined>(undefined);

export function useEpisodesPageContext() {
  const context = useContext(EpisodesPageContext);
  if (context === undefined) {
    throw new Error('EpisodesPageContext must be used as a child of EpisodesPage');
  }
  return context;
}

interface EpisodesPageProviderProps {
  children: ReactChild;
}

export function EpisodesPageProvider({ children }: EpisodesPageProviderProps) {
  const contextReducer = useReducer(reducer, {
    isTrialWarningModalOpen: false,
    filter: { name: '', sort: 'dateDesc' },
    loading: false,
    pagination: { totalPages: 1, offset: 0, limit: 10, height: 0 },
  });

  return (
    <EpisodesPageContext.Provider value={contextReducer}>{children}</EpisodesPageContext.Provider>
  );
}

type EpisodesPageReducerAction =
  | { type: 'set-playing-file-id'; id: string | undefined }
  | { type: 'set-page-loading'; loading: EpisodesPageState['loading'] }
  | { type: 'set-pagination'; pagination: Partial<EpisodesPageState['pagination']> }
  | { type: 'set-filter'; filter: Partial<EpisodesPageState['filter']> };

function reducer(state: EpisodesPageState, action: EpisodesPageReducerAction): EpisodesPageState {
  switch (action.type) {
    case 'set-playing-file-id': {
      return { ...state, playingFileId: action.id };
    }
    case 'set-filter': {
      return { ...state, filter: { ...state.filter, ...action.filter } };
    }
    case 'set-page-loading': {
      return { ...state, loading: action.loading };
    }
    case 'set-pagination': {
      return { ...state, pagination: { ...state.pagination, ...action.pagination } };
    }
    default: {
      throw new Error('Unhandled action');
    }
  }
}
