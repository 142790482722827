import { ErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';
import ErrorFallback from 'src/components/error-fallback/ErrorFallbackModal';
import { FC } from 'react';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import { Tier } from 'src/generated/graphql';
import PrivateRoute from './PrivateRoute';
import { isPage } from './helpers';

interface PublicRouteProps {
  component: FC;
  shouldRedirect?: boolean;
}

export default function PublicRoute({
  component: Component,
  shouldRedirect = true,
  ...props
}: PublicRouteProps) {
  const { permissions } = usePermissionsContext();

  if (!isPage(['/signup']) && permissions && permissions?.tier !== Tier.None) {
    if (shouldRedirect) {
      return <Navigate to="/" />;
    }
    return <PrivateRoute component={Component} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Component {...props} />
    </ErrorBoundary>
  );
}
