import colors from '../colors';

export default {
  baseStyle: {
    bg: 'podcastShade500',
    '--popper-arrow-bg': colors.podcastShade500,
    color: 'podcastShade100',
    fontSize: '14px',
  },
  variants: {
    alituWaves: {
      bg: 'podcastShade600',
      '--popper-arrow-bg': colors.podcastShade600,
      borderRadius: '8px',
      textAlign: 'center',
      py: '5px',
    },
  },
  defaultProps: {},
};
