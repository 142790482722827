import { Alert, AlertProps } from '@chakra-ui/react';
import WarningTriangleIcon from 'src/assets/icons/WarningTriangleIcon';
import colors from 'src/theme/colors';

export function ErrorAlert({ children, ...otherProps }: AlertProps) {
  return (
    <Alert
      minH="36px"
      border={`1px solid ${colors.AlituPink}`}
      bg={colors.AlituPinkLight}
      color={colors.podcastRed600}
      mt="12px"
      py="4px"
      borderRadius="6px"
      fontSize="13px"
      justifyContent="space-between"
      {...otherProps}
    >
      {children}
      <WarningTriangleIcon />
    </Alert>
  );
}
