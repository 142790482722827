import { Tooltip, Button } from '@chakra-ui/react';
import QuestionIcon from 'src/assets/icons/Question';
import QuestionMark from 'src/assets/icons/QuestionMark';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { ReactElement, cloneElement } from 'react';

function getHelpArticle({
  episodeId,
  assetId,
  uniqueId,
}: {
  episodeId: string;
  assetId: string;
  uniqueId: string;
}) {
  switch (window.location.pathname) {
    case `/episode/${episodeId}`:
      return 5572686; // using the episode builder
    case '/episodes':
      return 5572681; // how to add a new episode in Alitu
    case '/library':
      return 5615135; // using the file library
    case '/clip-genie':
      return 5612492; // how to use the clip genie
    case '/create-episode':
      return 5572683; // entering episode details
    case `/audio-editor/${episodeId}/${assetId}/${uniqueId}`:
      return 5572687;
    case `/upload/${episodeId}`:
      return 5572684; // how to upload your recordings
    case `/publish/${episodeId}`:
      return 5572688; // how to export publish your episode
    case `/record-call`:
      return 5612053; // how to record a call with Alitu
    default:
      return null;
  }
}

export default function Help({
  component,
  textButton = false,
}: {
  component?: ReactElement;
  textButton?: boolean;
}) {
  const urlParams = useParams() as { episodeId: string; assetId: string; uniqueId: string };
  const helpArticle = getHelpArticle(urlParams ?? {});

  const { showArticle } = useIntercom();

  if (!helpArticle) return null;

  const onOpen = () => {
    showArticle(helpArticle);
  };

  if (component) {
    return cloneElement(component, { onClick: onOpen });
  }

  return textButton ? (
    <Button variant="help" onClick={onOpen} rightIcon={<QuestionMark />} title="Help button">
      Help
    </Button>
  ) : (
    <Tooltip label={<>Need help?</>}>
      <button type="button" onClick={onOpen}>
        <QuestionIcon />
      </button>
    </Tooltip>
  );
}
