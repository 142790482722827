import { ApolloProvider } from '@apollo/client';
import ErrorFallback from 'src/components/error-fallback/ErrorFallbackModal';
import 'core-js';
import { ErrorBoundary } from 'react-error-boundary';
import { configure } from 'react-hotkeys';
import 'regenerator-runtime';
import { CacheBusterProvider } from 'src/components/cache-buster/CacheBusterContext';
import CachePopup from 'src/components/cache-buster/CachePopup';
import { FeatureFlagProvider } from 'src/components/feature-flags/FeatureFlagProvider';
import { ReactNode } from 'react';
import { PermissionsContextProvider } from 'src/components/permissions/PermissionsContext';
import ThemeProvider from '../theme/ThemeProvider';
import { client } from '../apollo/ApolloClient';
import { AdobeContextProvider } from '../components/adobe/AdobeContext';

configure({
  ignoreRepeatedEventsWhenKeyHeldDown: false,
});

// Wraps the app in providers that are used throughout the app
function AppProviders({ children }: { children: ReactNode }) {
  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <CacheBusterProvider>
        <ErrorBoundary onReset={() => window.location.reload()} FallbackComponent={ErrorFallback}>
          <ThemeProvider>
            <FeatureFlagProvider>
              <PermissionsContextProvider>
                <AdobeContextProvider>
                  {children}
                  <CachePopup />
                </AdobeContextProvider>
              </PermissionsContextProvider>
            </FeatureFlagProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </CacheBusterProvider>
    </ApolloProvider>
  );
}

export default AppProviders;
