import { selectAnatomy } from '@chakra-ui/anatomy';
import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys,
);

const $height = cssVar('input-height');

const baseStyle = definePartsStyle({
  field: {
    color: 'podcastShade500',
    fontFamily: fonts.normal,
    borderRadius: '12px !important',
  },
});

const primary = definePartsStyle({
  field: {
    boxShadow: 'inset 0px -3px 6px rgba(205, 204, 227, 0.15)',
    border: '1px solid',
    borderColor: 'podcastShade300',
    pl: '20px',
    _hover: {
      boxShadow: 'none',
    },
    _focus: {
      border: '2px solid',
      borderColor: colors.podcastPurple,
      pl: '19px',
      _invalid: {
        border: `3px solid ${colors.AlituPink}`,
      },
    },
    _invalid: {
      border: `1px solid ${colors.AlituPink}`,
      color: 'podcastRed600',
      boxShadow: 'inset 0px -3px 6px rgba(239, 57, 113, 0.05)',
    },
  },
});

const small = defineStyle({
  [$height.variable]: '36px',
  fontSize: '13px',
  paddingInlineEnd: '8',
});

const large = defineStyle({
  [$height.variable]: '48px',
  fontSize: '16px',
  paddingInlineEnd: '8',
});

const sizes = {
  small: definePartsStyle({ field: small, element: small, addon: small, group: small }),
  large: definePartsStyle({ field: large, element: large, addon: large, group: large }),
};

export default defineMultiStyleConfig({
  baseStyle,
  variants: { primary },
  defaultProps: { variant: 'primary', size: 'large' },
  sizes,
});
