import { useEffect, useState } from 'react';
import { useSubscriptionPriceLazyQuery } from 'src/generated/graphql';
import { Plan } from '../types';

function getCountryCode(countryCode: string, isEuropeanUnion: boolean) {
  if (countryCode === 'GB') return 'GB';
  if (isEuropeanUnion) return 'EU';
  return countryCode;
}
interface useFetchCostArgs {
  plan: Plan;
  countryCode: string;
  isEuropeanUnion: boolean;
}

export default function useFetchCost({ plan, countryCode, isEuropeanUnion }: useFetchCostArgs) {
  const [cost, setCost] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [currency, setCurrency] = useState('usd');

  const [getSubscriptionPrice, { loading: isLoading }] = useSubscriptionPriceLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({
      subscriptionPrice: { currency: _currency, price: _price, symbol: _currencySymbol },
    }) => {
      setCost(parseFloat(_price));
      setCurrencySymbol(_currencySymbol);
      setCurrency(_currency);
    },
  });

  useEffect(() => {
    const isFreeTier =
      plan === 'monthly_hosting_1k' ||
      plan === 'yearly_hosting_1k' ||
      plan === 'monthly_hosting_5k' ||
      plan === 'yearly_hosting_5k';
    // Do not fetch the 1k or 5k price since it no longer exists in stripe as it's been archived
    // as we don't need a stripe price for a free tier
    if (isFreeTier) return;
    getSubscriptionPrice({
      variables: {
        countryCode: getCountryCode(countryCode || 'US', isEuropeanUnion),
        planType: plan || 'monthly',
      },
    });
  }, [countryCode, plan, getSubscriptionPrice, isEuropeanUnion]);
  return { cost, currencySymbol, currency, isLoading };
}
