/**
 * HACK: Due to the architectures and issues with the Adobe Embed SDK, the CCEverywhere
 * instance is being stored in a React Context. The purpose is to ensure 'initialise' is
 * only ever called once. A reference to the CCEverywhere object that it returns must be
 * maintained as subsequent calls to 'initialize' throw an error.
 *
 * The SDK contains a 'terminate' function, which should be used to de-initialise the
 * active instance, however as of writing, this does not work as intended. This context
 * can be refactored into a custom hook once this issue is resolved.
 */
import { ReactNode, createContext, useContext, useState, useMemo } from 'react';
import assert from 'src/utils/assert';

// Methods used from the CCEverywhere instance
interface AdobeCCEverywhere {
  editor: Editor;
}

interface Editor {
  create(
    docConfig: { canvasSize: string },
    appConfig: { callbacks: any; allowedFileTypes: string[] },
    exportConfig: undefined,
    containerConfig: { zIndex: number },
  ): void;
  createWithAsset(
    docConfig: { asset: { type: 'image'; dataType: 'base64'; data: unknown } },
    appConfig: { callbacks: any; allowedFileTypes: string[] },
    exportConfig: undefined,
    containerConfig: { zIndex: number },
  ): void;
}

interface AdobeState {
  initialiseCCEverywhere: () => void;
  ccEverywhere: AdobeCCEverywhere | undefined;
  ccEverywhereLoading: boolean;
}

const AdobeContext = createContext<AdobeState | undefined>(undefined);

export function AdobeContextProvider({ children }: { children: ReactNode }) {
  const [ccEverywhere, setCCEverywhere] = useState<AdobeCCEverywhere>();
  const [loading, setLoading] = useState<boolean>(true);

  const state = useMemo(() => {
    return {
      initialiseCCEverywhere: async () => {
        if (ccEverywhere) {
          return;
        }

        // Load script async to make it play nice with Webpack
        const script = document.createElement('script');
        script.src = 'https://cc-embed.adobe.com/sdk/v4/CCEverywhere.js';
        script.async = true;

        script.onload = async () => {
          /**
           * Initialise the Adobe SDK
           *
           * The 'initialize' function returns an instance of the CCEverywhere object. This
           * initialisation can only be performed once as subsequent calls throw an error.
           */

          // @ts-ignore: Property 'CCEverywhere' does not exist on type 'Window & typeof globalThis'.
          const instance = await window.CCEverywhere.initialize({
            clientId: 'de258af49d7f4ee2801315ddb3eff6db',
            appName: 'alitu',
          });

          setCCEverywhere(instance);
          setLoading(false);
        };

        document.body.appendChild(script);

        // TODO:  When Adobe fixes the SDK's 'terminate' function, perform cleanup on unmount
      },
      ccEverywhere,
      ccEverywhereLoading: loading,
    };
  }, [ccEverywhere, loading]);

  return <AdobeContext.Provider value={state}>{children}</AdobeContext.Provider>;
}

export function useAdobeContext() {
  const context = useContext(AdobeContext);
  assert(context, 'Adobe context must be used as a child of the adobe context provider');
  return context;
}
