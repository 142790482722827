import { Box, BoxProps } from '@chakra-ui/react';
import { colours, fonts } from 'src/styles/variables';

function AttentionBox(props: BoxProps) {
  return (
    <Box
      border="1px solid"
      borderColor="podcastPurple800"
      color={colours.white}
      bg="podcastPurple800"
      display="inline-block"
      borderRadius="6px"
      px="8px"
      py="6px"
      textShadow="0px 1px 1px rgba(0, 0, 0, 0.15)"
      fontSize="12px"
      lineHeight="12px"
      fontFamily={fonts.bold}
      textAlign="center"
      {...props}
    />
  );
}

export default AttentionBox;
