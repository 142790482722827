import { ChakraProvider } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Fonts from './Fonts';
import theme from './theme';

interface ChakraProps {
  children: ReactNode;
}

function ThemeProvider({ children }: ChakraProps) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Fonts />
      {children}
    </ChakraProvider>
  );
}

export default ThemeProvider;
