import { fonts } from 'src/styles/variables';

const Heading = {
  baseStyle: {
    fontFamily: fonts.bold,
    lineHeight: '1.5em',
    letterSpacing: '-0.02em',
    color: 'podcastShade700',
    fontWeight: 'normal !important',
  },
  sizes: {
    none: {},
    h1: {
      fontSize: ['30px', '36px', '48px'],
      lineHeight: ['42px', '48px', '60px'],
    },
    h2: {
      fontSize: ['24px', '30px', '36px'],
      lineHeight: ['36px', '42px', '48px'],
    },
    h3: {
      fontSize: ['20px', '24px', '30px'],
      lineHeight: ['30px', '36px', '42px'],
    },
    h4: {
      fontSize: ['20px', '24px'],
      lineHeight: ['20px', '36px'],
    },
    h5: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  variants: {
    none: {},
    statsHeader: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    modalTitle: {
      m: '0',
      fontSize: '24px',
      lineHeight: '36px',
    },
    toastTitle: {
      color: 'white',
      fontSize: '16px',
      lineHeight: '18px',
      m: '0',
      wordBreak: 'break-word',
    },
    subscription: {
      m: '0',
      color: 'podcastShade500',
      fontSize: '22px',
      lineHeight: '24px',
    },
    loading: {
      m: '0',
      color: 'podcastShade600',
      fontSize: '20px',
      lineHeight: '30px',
    },
    contentBoxHeading: {
      fontSize: '22px',
      color: 'podcastShade600',
      lineHeight: '30px',
      m: '0 0 18px',
      textAlign: 'left',
    },
    sectionHeader: {
      fontFamily: fonts.bold,
      fontSize: '24px',
      color: 'podcastShade700',
      lineHeight: '36px',
      w: 'max-content',
      m: '0 0 3px',
      p: 0,
    },
  },
  defaultProps: {
    variant: 'none',
    size: 'none',
  },
};

export default Heading;
