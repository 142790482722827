import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import robotHead from 'src/assets/images/robotHead.svg';
import { ReactNode } from 'react';
import { colours, fonts } from 'src/styles/variables';

// We cannot nest a button inside another button, so instead style the Typeform button to look like our own buttons
interface YesNoModalProps {
  isOpen: boolean;
  modalTitle: string;
  modalText: string;
  noBtnText?: string;
  yesBtnText?: string;
  noHandler?: () => void;
  yesHandler?: () => void;
  isWarning?: boolean;
  disabled?: boolean;
  onCancel?: () => void;
  isRobotIcon?: boolean;
  closeOnOverlayClick?: boolean;
  Footer?: ReactNode;
  Body?: ReactNode;
}

export default function YesNoModal({
  isOpen,
  modalTitle,
  modalText,
  noBtnText,
  yesBtnText,
  noHandler,
  yesHandler,
  onCancel,
  isWarning = false,
  disabled = false,
  isRobotIcon = false,
  closeOnOverlayClick = true,
  Footer,
  Body,
}: YesNoModalProps) {
  function onModalClose() {
    if (onCancel) {
      onCancel();
    } else if (noHandler) {
      noHandler();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered
      scrollBehavior="inside"
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={colours.white} p="28px 30px 14px 30px">
          {isRobotIcon && <Image src={robotHead} />}
          <ModalCloseButton h="42px" w="42px" borderRadius="8px" right="25px" top="27px" />
          <Heading as="h4" variant="modalTitle" mt={isRobotIcon ? '8px' : '0'}>
            {modalTitle}
          </Heading>
        </ModalHeader>
        <ModalBody p="0 30px 18px">
          <Text>{modalText}</Text>
          {Body}
        </ModalBody>
        <Flex p="0 30px 18px" flexFlow="column">
          <Flex columnGap="10px">
            {yesBtnText && (
              <Button
                width="fit-content"
                variant={isWarning ? 'destructive' : 'primary'}
                onClick={yesHandler}
                isDisabled={disabled}
              >
                {yesBtnText}
              </Button>
            )}
            {noBtnText && (
              <Button
                variant="tertiary"
                fontFamily={fonts.bold}
                width="fit-content"
                onClick={noHandler}
              >
                {noBtnText}
              </Button>
            )}
          </Flex>
          {Footer}
        </Flex>
      </ModalContent>
    </Modal>
  );
}
