import { ReactElement, ReactNode, useEffect } from 'react';
import { FlagProvider, useUnleashContext } from '@unleash/proxy-client-react';
import { useUserCache } from 'src/apollo/cache';
import unleashConfig from './unleashConfig';

function UnleashContext() {
  const userCache = useUserCache();
  const updateUnleashContext = useUnleashContext();
  useEffect(() => {
    updateUnleashContext({
      userId: userCache?.id,
      properties: {
        isTester: userCache?.isTester ? 'true' : 'false',
        ...(userCache?.role && { role: userCache?.role }),
      },
    });
  }, [updateUnleashContext, userCache?.isTester, userCache?.id, userCache?.role]);
  return null;
}

export function FeatureFlagProvider({
  children,
}: {
  children: ReactElement | ReactElement[] | ReactNode;
}) {
  return (
    <FlagProvider config={unleashConfig}>
      <UnleashContext />
      {children}
    </FlagProvider>
  );
}
