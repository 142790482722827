const colors = {
  podcastDarkIndigo: '#1C1463',
  podcastDarkIndigo700: '#2D2290',
  podcastDarkIndigo500: '#3C2EBB',
  podcastVividBlue: '#2945FF',
  podcastVividBlue700: '#001DDE',
  podcastVividBlue800: '#0017AF',
  podcastBlue: '#00B5FF',
  podcastBlue600: '#12A6E2',
  podcastBlue700: '#007DB7',
  podcastLightBlue: '#BFD6FF',
  podcastLightBlue100: '#E5F8FF',
  podcastLightBlue200: '#E3EDFF',
  podcastLightBlue300: '#B0CAF8',
  podcastPurple: '#7B2BEE',
  podcastPurple100: '#F2EAFD',
  podcastPurple500: '#883DF4',
  podcastPurple800: '#6A1FD6',
  podcastPink: '#FF82EB',
  podcastPink700: '#EE3CD1',
  podcastPink600: '#F960E0',
  podcastRed: '#E64643',
  podcastRed100: '#FDECEC',
  podcastRed400: '#EF5B59',
  podcastRed600: '#D4322F',
  podcastAquaGreen: '#00D4BD',
  podcastAquaGreen700: '#15C3B1',
  podcastAquaGreen300: '#0CEAD2',
  podcastPaleTurquoise: '#8AFFDB',
  podcastPaleTurquoise200: '#D6FFF2',
  podcastPaleTurquoise400: '#79F5CF',
  podcastYellow: '#FFED2B',
  podcastYellow100: '#FFFCDF',
  podcastYellow800: '#79452E',
  podcastShade800: '#1B2432',
  podcastShade700: '#303B4B',
  podcastShade600: '#455265',
  podcastShade500: '#5A687C',
  podcastShade400: '#8F9AAA',
  podcastShade300: '#CCD2DC',
  podcastShade200: '#E6EBEF',
  podcastShade100: '#F4F6F8',
  podcastOrange300: '#FFB870',
  podcastOrange: '#FF9D42',
  podcastOrange500: '#E77F1D',
  transcriptSpeaker: [
    '#775cef',
    '#73c5c9',
    '#86cb64',
    '#fcb353',
    '#ed4745',
    '#be90d4',
    '#486ce8',
    '#57d99a',
    '#fac73c',
    '#ff7c47',
    '#ff71a4',
  ],
  white: '#ffffff',
  // old colours
  AlituPurpleGrey: '#CDCCE3',
  AlituPumpPurple: '#544FB0',
  AlituPurpleSlateBlue: '#7D78DE',
  AlituPurpleMaximumBlue: '#ABA6EE',
  AlituPaleLavender: '#DAD3FF',
  AlituLavender: '#E2DFF6',
  AlituRedMedium: '#AA0000',
  AlituRedDarkest: '#721C24',
  AlituOrangeLight: '#FA755A',
  AlituOrange: '#FDAB0D',
  AlituOrangeDark: '#FE9801',
  AlituTangerine: '#EC8D00',
  AlituPinkLight: '#FFF3F7',
  AlituPinkPale: '#F8D7DA',
  AlituOrchidPink: '#F5C6CB',
  AlituPink: '#E9BDCB',
  AlituGreen: '#00B67A',
  AlituBlueLight: '#B4D0DB',
  AlituBlue: '#009AAC',
  AlituChampagne: '#F3D6AB',
  AlituMaximumBluePurple: '#AEABDF',
};

export default colors;
