import { Button, Text } from '@chakra-ui/react';
import { ComponentProps } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { fonts } from 'src/styles/variables';
import { CacheBusterContextType, useCacheBusterContext } from '../cache-buster/CacheBusterContext';
import ErrorModal from './ErrorModal';

interface ErrorModalFallbackProps extends FallbackProps {
  heading?: ComponentProps<typeof ErrorModal>['heading'];
  Body?: ComponentProps<typeof ErrorModal>['Body'];
  Footer?: ComponentProps<typeof ErrorModal>['Body'];
}

export default function ErrorFallbackModal({
  error,
  heading,
  resetErrorBoundary,
  Body,
  Footer,
}: ErrorModalFallbackProps) {
  const cacheBuster = useCacheBusterContext();

  if (cacheBuster.isOpen) {
    return (
      <ErrorFallbackForUpdateError
        cacheBuster={cacheBuster}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  }

  return (
    <ErrorModal
      isOpen
      onClose={resetErrorBoundary}
      heading={heading}
      Body={
        Body ?? (
          <Text color="podcastShade500">
            {error.message.replaceAll('Error: ', '')}. Sorry about that!
          </Text>
        )
      }
      Footer={Footer}
      errorMessage={error.message}
    />
  );
}

interface ErrorFallbackForUpdateErrorProps {
  cacheBuster: CacheBusterContextType;
  resetErrorBoundary: ErrorModalFallbackProps['resetErrorBoundary'];
}

/**
 * @description
 * Render this component when cache buster modal is open and the error fallback is triggered
 * This is for the occasional case of out of sync frontend and commander state causing an error
 */
function ErrorFallbackForUpdateError({
  cacheBuster,
  resetErrorBoundary,
}: ErrorFallbackForUpdateErrorProps) {
  return (
    <ErrorModal
      isOpen
      onClose={() => {
        cacheBuster.onClose();
        resetErrorBoundary();
      }}
      heading="A new version of Alitu is available"
      Body={<Text fontFamily={fonts.bold}>Please update to continue using Alitu</Text>}
      Footer={
        <Button h="48px" w="100%" onClick={cacheBuster.refresh}>
          Update
        </Button>
      }
    />
  );
}
