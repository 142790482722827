export const colours = {
  lightPurple: '#7772dd',
  darkPurple: '#5a54d6',
  transparent: 'transparent',
  purple: '#827de0',
  white: '#ffffff',
  black: '#000000',
};

export const fonts = {
  normal: 'Montserrat-Medium',
  bold: 'Montserrat-SemiBold',
};
