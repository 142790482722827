import { Flex, FlexProps } from '@chakra-ui/react';
import colors from 'src/theme/colors';
import { useMenuContext } from '../../MenuContext';

export default function SidebarMenuContainer({
  children,
  menuState,
  ...props
}: FlexProps & { menuState: ReturnType<typeof useMenuContext>[0] }) {
  return (
    <Flex
      as="aside"
      direction="column"
      px={menuState === 'open' ? '20px' : '12px'}
      w={menuState === 'open' ? '260px' : '72px'}
      top={0}
      height="100vh"
      position="sticky"
      zIndex="100"
      overflow={menuState === 'open' ? 'auto' : 'initial'}
      bg={`linear-gradient(180deg, ${colors.podcastPurple} 8.33%, ${colors.podcastPurple800} 100%);`}
      {...props}
    >
      {children}
    </Flex>
  );
}
