import { fonts } from 'src/styles/variables';
import colors from '../colors';

const Form = {
  baseStyle: {
    helperText: {
      fontFamily: fonts.normal,
      fontSize: 13,
      px: '20px',
      py: '7px',
      color: colors.podcastPurple800,
      bg: colors.podcastShade100,
      border: `1px solid ${colors.AlituMaximumBluePurple}`,
      borderRadius: '6px',
      minHeight: '36px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  variants: {
    primary: {},
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Form;
