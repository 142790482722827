import { useToast, UseToastOptions } from '@chakra-ui/react';
import { useMemo } from 'react';
import Toast from './Toast';

/**
 * @description
 * A small abstraction over the Chakra useToast hook to add some defaults.
 * @returns a toast function which can be used to show a toast and customize toast options through the function param.
 */
export default function useAlituToast() {
  const toast = useToast();

  return useMemo(() => {
    return {
      ...toast,
      render: (
        options: UseToastOptions & {
          title: string;
          type?: string;
          action?: { text: string; onClick: () => void };
        },
      ) =>
        toast({
          isClosable: true,
          position: 'bottom-left',
          render: props => (
            <Toast
              description={options.description}
              type={options.type}
              action={options.action}
              {...props}
              title={options.title}
            />
          ),
          ...options,
        }),
    };
  }, [toast]);
}
