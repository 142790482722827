import { useLocation } from 'react-router-dom';

export default function usePageSelected() {
  const { pathname } = useLocation();
  return {
    episodes: pathname.includes('episodes'),
    callRecorder: pathname.includes('call'),
    clipGenie: pathname.includes('clip-genie'),
    library: pathname.includes('library'),
    settings: pathname.includes('settings'),
    account: pathname.includes('account'),
    statistics: pathname.includes('statistics'),
    upgrade: pathname.includes('upgrade'),
    myShows: pathname.includes('my-shows'),
  };
}
