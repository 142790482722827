import { useSubscriptionCache } from 'src/apollo/cache';
import Modal from 'src/components/modal/Modal';
import { Podcast } from 'src/generated/graphql';
import { Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackModal from 'src/components/error-fallback/ErrorFallbackModal';
import getPrettyDate from 'src/utils/getPrettyDate';
import AddNewShow from './AddNewShow';
import { useMyShowsContext } from './MyShowsContext';
import RemoveShow from './RemoveShow';
import MyShowsList from './MyShowsList';
import RenewShow from './RenewShow';

interface MyShowsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function MyShowsModal({ isOpen, onClose }: MyShowsModalProps) {
  const { contentType, setContentType, onSwitchToShow } = useMyShowsContext();
  const navigate = useNavigate();
  const subscription = useSubscriptionCache();
  const currentPeriodEnd = getPrettyDate({
    // subscription is not asserted because it caused issues with subscription cache when switching to account via admin
    date: String(subscription?.current_period_end || ''),
    shortDate: true,
    isUnixTimestamp: true,
  });

  function getHeading() {
    switch (contentType) {
      case 'list-shows':
        return 'My Shows';
      case 'remove-show':
        return 'Are you sure?';
      case 'add-new-show':
        return 'Add new show';
      case 'show-removed-confirmation':
        return 'Done!';
      case 'show-added-confirmation':
        return 'Welcome to your new show';
      case 'renew-show':
        return 'Renew show?';
      case 'show-renewed-confirmation':
        return 'Show renewed';
    }
  }

  function onModalClose() {
    setContentType('list-shows');
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      heading={getHeading()}
      width={contentType === 'list-shows' ? '700px' : '600px'}
      Body={
        <ErrorBoundary
          FallbackComponent={fallbackProps => (
            <ErrorFallbackModal {...fallbackProps} heading="Cannot add show" />
          )}
        >
          {contentType === 'list-shows' && <MyShowsList />}
          {contentType === 'remove-show' && <RemoveShow />}
          {contentType === 'add-new-show' && (
            <AddNewShow onSwitchToShow={(id: Podcast['id']) => onSwitchToShow(id, true)} />
          )}
          {contentType === 'show-removed-confirmation' && (
            <>
              <Text>{`Your show will be removed on ${currentPeriodEnd}`}.</Text>
              <Button mt="20px" onClick={onModalClose}>
                Okay
              </Button>
            </>
          )}
          {contentType === 'show-added-confirmation' && (
            <>
              <Text>
                Your new show has been set up successfully. Thanks for choosing Alitu again!
              </Text>
              <Button
                mt="20px"
                onClick={() => {
                  navigate('/');
                  onModalClose();
                }}
              >
                Okay
              </Button>
            </>
          )}
          {contentType === 'renew-show' && <RenewShow />}
          {contentType === 'show-renewed-confirmation' && (
            <>
              <Text>Welcome back to Alitu!</Text>
              <Button
                mt="20px"
                onClick={() => {
                  onModalClose();
                }}
              >
                Okay
              </Button>
            </>
          )}
        </ErrorBoundary>
      }
    />
  );
}
