import { useNavigate } from 'react-router-dom';

export default function useNavigateTo() {
  const navigate = useNavigate();

  return {
    episodes: () => navigate('/episodes'),
    callRecorder: () => navigate('/record-call'),
    clipGenie: () => navigate('/clip-genie'),
    library: () => navigate('/library'),
    settings: () => navigate('/settings'),
    account: () => navigate('/my-account'),
  };
}
