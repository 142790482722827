import { Link, LinkProps } from '@chakra-ui/react';
import { MouseEvent as ReactMouseEvent, ReactNode, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

interface SupportLinkProps extends LinkProps {
  children: ReactNode;
  message?: string;
}

function SupportLink({ children, message, ...props }: SupportLinkProps) {
  const { showNewMessage: sendIntercomMessage } = useIntercom();

  const handleClick = useCallback(
    (e: ReactMouseEvent) => {
      e.preventDefault();
      sendIntercomMessage(message);
    },
    [message, sendIntercomMessage],
  );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link href="#" onClick={handleClick} {...props}>
      {children}
    </Link>
  );
}

export default SupportLink;
