import hexToRgb from 'src/utils/hexToRgb';
import colors from '../colors';

const Modal = {
  defaultProps: {
    isCentered: true,
  },
  baseStyle: {
    header: {
      fontSize: '30px',
      color: colors.podcastShade600,
      lineHeight: '36px',
      borderRadius: '8px 8px 0px 0px',
      bg: 'podcastShade100',
      p: '27px 40px',
    },
    dialog: {
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.06)',
      borderRadius: '8px',
    },
    body: {
      color: colors.podcastShade500,
      fontSize: '16px',
      pt: 0,
      pb: '18px',
    },
    footer: {
      borderRadius: '0px 0px 8px 8px',
    },
    overlay: {
      bg: hexToRgb(colors.podcastShade700, 0.7),
    },
  },
};

export default Modal;
