import { useEffect } from 'react';
import { useSubscriptionCache } from 'src/apollo/cache';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  useGetSubscriptionLazyQuery,
  useShowLazyQuery,
  useUserLazyQuery,
} from 'src/generated/graphql';
import useIsPublicRoute from './useIsPublicRoute';
import useShouldIntercomInitialise from './useShouldIntercomInitialise';

function useFetchUserAndProject({ isPrivateRoute }: { isPrivateRoute: boolean }) {
  const [getUser, query] = useUserLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [getShow] = useShowLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [getSubscription] = useGetSubscriptionLazyQuery({
    fetchPolicy: 'cache-first',
  });

  // The queries below are mostly needed to populate user, project, and subscription cache for cypress tests
  // But also in cases where the user is already logged in and we need to ensure the cache is populated
  useEffect(() => {
    if (isPrivateRoute) {
      getUser();
    }
  }, [getUser, isPrivateRoute]);

  useEffect(() => {
    if (isPrivateRoute && query.data?.user) {
      getShow({ variables: { id: query.data.user.activeProjectId } });
    }
  }, [getShow, isPrivateRoute, query.data?.user]);

  useEffect(() => {
    if (isPrivateRoute && query.data?.user) {
      getSubscription();
    }
  }, [getSubscription, isPrivateRoute, query.data?.user]);

  return {
    ...query,
    user: query.data?.user,
  };
}

function useRouterRedirects() {
  const location = useLocation();
  const isPublicRoute = useIsPublicRoute();
  const { user, called, loading } = useFetchUserAndProject({ isPrivateRoute: !isPublicRoute });
  const { update } = useIntercom();
  const subscription = useSubscriptionCache();
  const navigate = useNavigate();
  const shouldIntercomIntialize = useShouldIntercomInitialise();

  // Intercom needs to be pinged on every route change, so onboarding works properly
  useEffect(() => {
    shouldIntercomIntialize &&
      update({
        name: user && `${user?.firstName || ''} ${user?.lastName || ''}`,
        email: user?.email,
        userId: user?.id,
        lastRequestAt: Math.floor(new Date().getTime() / 1000).toString(),
        customAttributes: { subscription_status: subscription?.status },
      });
  }, [location.pathname, shouldIntercomIntialize, subscription?.status, update, user]);

  useEffect(() => {
    if (!isPublicRoute && !user && called === true && loading === false) {
      const hasAlituAccount: boolean = JSON.parse(
        localStorage.getItem('has-alitu-account') ?? 'false',
      );
      navigate(hasAlituAccount ? '/login' : '/signup');
    }
  }, [called, isPublicRoute, loading, navigate, user]);

  useEffect(() => {
    if (location.pathname === '/' && user) {
      navigate('/episodes');
    }
  }, [location.pathname, navigate, user]);
}

export default useRouterRedirects;
