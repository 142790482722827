const unleashConfig = {
  url: 'https://features.alitu.com/proxy',
  clientKey: '53157121A37ED9FC953ABD4FAD7A594CA176ADFF184698FAF591F122BF',
  refreshInterval: 15,
  appName: 'alitu',
  // local development environment uses staging
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
};

export default unleashConfig;
