import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useSubscriptionCache, useUserCache } from 'src/apollo/cache';
import AlituTickIcon from 'src/assets/icons/AlituTickIcon';
import RightArrow from 'src/assets/icons/RightArrow';
import { Podcast, useAddShowMutation } from 'src/generated/graphql';
import useApolloErrorHandler from 'src/hooks/useApolloErrorHandler';
import { Input } from 'src/pages/settings/form/FormComponents';
import colors from 'src/theme/colors';
import assert from 'src/utils/assert';
import useGetExtraShowCost from './useGetExtraShowCost';

export default function AddNewShow({
  onSwitchToShow,
}: {
  onSwitchToShow: (id: Podcast['id']) => void;
}) {
  const [showName, setShowName] = useState<string>('');
  const user = useUserCache();
  const subscription = useSubscriptionCache();
  assert(subscription?.plans, 'subscription or subscription plans not found in cache');
  assert(user, 'user not found in cache');
  const interval = subscription.plans[0].interval as 'month' | 'year';
  const { cost, currencySymbol, originalCost } = useGetExtraShowCost();

  const [addShow, { loading, error }] = useAddShowMutation({
    variables: {
      name: showName,
    },
    onCompleted: res => {
      onSwitchToShow(res.addShow);
    },
  });

  useApolloErrorHandler(error);

  return (
    <>
      <Flex>
        <Box
          fontSize="30px"
          fontWeight="600"
          color={colors.podcastShade300}
          position="relative"
          mr="17px"
        >
          {`${currencySymbol}${originalCost}`}
          <Box
            w="68px"
            h="3px"
            bg={colors.podcastShade400}
            transform="rotate(-15deg)"
            top="21px"
            left="-5px"
            position="absolute"
          />
        </Box>
        <Box fontSize="30px" fontWeight="600" color={colors.podcastShade700}>
          {`${currencySymbol}${cost}/${interval}`}
        </Box>
      </Flex>
      <Box mt="14px">
        <Flex alignItems="center" mb="10px">
          <AlituTickIcon />
          <Text ml="12px">Discounted access to Alitu - save over 34%</Text>
        </Flex>
        <Flex alignItems="center" mb="10px">
          <AlituTickIcon />
          <Text ml="12px">All shows under one roof to keep you organised</Text>
        </Flex>
        <Flex alignItems="center" mb="10px">
          <AlituTickIcon />
          <Text ml="12px">Single billing account to keep things simple</Text>
        </Flex>
      </Box>
      <Divider variant="signUp" mt="30px" mb="30px" />
      <Input
        id="new-show-name"
        label="New show name"
        placeholder="e.g. My second show"
        onChange={e => setShowName(e.target.value)}
      />
      <Button
        isDisabled={!showName || loading}
        onClick={() => addShow()}
        rightIcon={<RightArrow />}
        w="100%"
        mt="60px"
      >
        {loading ? 'Adding show' : 'Add to subscription'}
      </Button>
    </>
  );
}
