import { Button as ChakraButton, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import RightChevron from 'src/assets/icons/RightChevron';
import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import MenuAvatar from './MenuAvatar';
import { useMenuContext } from '../../MenuContext';
import usePageSelected from '../../hooks/usePageSelected';

export default function AccountMenuButton() {
  const [menuState] = useMenuContext();
  const isMenuOpen = menuState === 'open';
  const selected = usePageSelected();

  return (
    <ChakraButton
      as={Link}
      to="/my-account"
      justifyContent="flex-start"
      variant="flat"
      padding={isMenuOpen ? '10px' : '5px'}
      textTransform="none"
      width={isMenuOpen ? '100%' : '48px'}
      background={selected.account ? 'white' : 'transparent'}
      color={selected.account ? colors.podcastPurple800 : 'white'}
      _hover={{ background: selected.account ? 'white' : 'rgba(255, 255, 255, 0.15)' }}
    >
      <MenuAvatar />
      {isMenuOpen && (
        <>
          <Text
            ml="10px"
            color={selected.account ? colors.podcastPurple800 : 'white'}
            fontSize="16px"
            fontFamily={fonts.bold}
          >
            Account
          </Text>
          <RightChevron ml="10px" width="7px" height="15px" />
        </>
      )}
    </ChakraButton>
  );
}
