import { Box, Button, ButtonProps, Text } from '@chakra-ui/react';
import { fonts } from 'src/styles/variables';

import colors from 'src/theme/colors';
import hexToRgb from 'src/utils/hexToRgb';

interface FilterProps extends ButtonProps {
  count?: number;
  text: string;
  isSelected: boolean;
}

export default function FilterButton({ count, text, isSelected, ...props }: FilterProps) {
  return (
    <Button
      variant="empty"
      paddingTop="14px !important"
      pb="15px !important"
      gridGap="6px"
      textTransform="capitalize"
      borderBottom={
        isSelected === true ? `3px solid ${colors.podcastPurple}` : '3px solid transparent'
      }
      _focus={{ boxShadow: 'none' }}
      {...props}
    >
      <Text variant="darker" fontFamily={fonts.bold} fontSize="18px">
        {text}
      </Text>
      <CountTag count={count} />
    </Button>
  );
}

function CountTag({ count }: { count: FilterProps['count'] }) {
  return (
    <Box
      p="3px 4px"
      fontSize="9px"
      position="relative"
      bg={hexToRgb(colors.podcastPurple, 0.1)}
      borderRadius="6px"
    >
      <Text color={colors.podcastPurple} fontSize="13px" lineHeight="12px !important">
        {count}
      </Text>
    </Box>
  );
}
