import { useEffect, useState } from 'react';

export default function useGetLocalStorage(key: string, defaultValue = false) {
  const [value, setValue] = useState<boolean>(defaultValue);

  useEffect(() => {
    const updateValue = () => {
      let val = false;
      try {
        val = localStorage.getItem(key)
          ? Boolean(JSON.parse(localStorage.getItem(key) ?? ''))
          : false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Couldn't parse local storage item "${key}"`, e);
      }

      setValue(val);
    };
    updateValue();
    window.addEventListener('storage', updateValue);

    return () => {
      window.removeEventListener('storage', updateValue);
    };
  }, [value, setValue, key]);
  return value;
}
