import { useParams } from 'react-router-dom';
import { Episode, EpisodeQuery, Exact, useEpisodeQuery } from 'src/generated/graphql';
import { QueryResult } from '@apollo/client';

interface UseFetchEpisode extends QueryResult<EpisodeQuery, Exact<{ id: string }>> {
  episode?: Omit<Episode, 'logs'>;
}

export default function useFetchEpisode(id?: string): UseFetchEpisode {
  const { episodeId: paramsEpisodeId = '' } = useParams();
  const episodeId = id || paramsEpisodeId;

  const { refetch, ...query } = useEpisodeQuery({
    fetchPolicy: 'cache-first', // cache first to prevent unnecessary requests between episodes and episode
    variables: { id: episodeId },
  });

  return { ...query, refetch, episode: query.data?.episode };
}
