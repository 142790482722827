import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useUserCache } from 'src/apollo/cache';

type MenuContextType = ['open' | 'closed', Dispatch<SetStateAction<'open' | 'closed'>>] | undefined;

const MenuContext = createContext<MenuContextType>(undefined);

export function useMenuContext() {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error('Menu context can only be used as a child of MenuProvider');
  }
  return context;
}

export function MenuProvider({
  children,
}: {
  children: ReactElement | ReactElement[] | ReactNode;
}) {
  const [isLessThan700] = useMediaQuery('(max-width: 1080px)');
  const user = useUserCache();

  const getLocalStorageMenuState = useCallback(
    () =>
      (localStorage.getItem(`${user?.id}-alitu-menu-state`) as 'open' | 'closed' | null) ?? 'open',
    [user?.id],
  );

  const contextWithSetter = useState<'open' | 'closed'>(getLocalStorageMenuState());
  const [context, setContext] = contextWithSetter;

  useEffect(() => {
    setContext(getLocalStorageMenuState());
  }, [getLocalStorageMenuState, setContext, user?.id]);

  useEffect(() => {
    localStorage.setItem(`${user?.id}-alitu-menu-state`, context);
  }, [context, user?.id]);

  useEffect(() => {
    if (isLessThan700 === true) {
      setContext('closed');
    }
  }, [isLessThan700, setContext]);

  return <MenuContext.Provider value={contextWithSetter}>{children}</MenuContext.Provider>;
}
