import { useFlag, useUnleashClient } from '@unleash/proxy-client-react';
import { useEffect, useState } from 'react';

const checkLocalStorage = (flagName: string) => {
  const key = `flag:${flagName}`;
  let flagOverride = null;
  try {
    flagOverride = localStorage.getItem(key)
      ? Boolean(JSON.parse(localStorage.getItem(key) ?? ''))
      : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Couldn't understand value for feature flag key in localStorage "${key}"`, e);
  }
  return flagOverride;
};

export function useGetAllFeatureFlags() {
  const client = useUnleashClient();
  const toggles = client.getAllToggles();
  return toggles;
}

export default function useFeatureFlag(flagName: string) {
  const remoteFlag = useFlag(flagName);
  const [localOverride, setLocalOverride] = useState<boolean | null>(checkLocalStorage(flagName));

  useEffect(() => {
    window.addEventListener('storage', () => {
      setLocalOverride(checkLocalStorage(flagName));
    });

    return function cleanup() {
      window.removeEventListener('storage', () => {
        setLocalOverride(checkLocalStorage(flagName));
      });
    };
  }, [flagName]);

  return localOverride ?? remoteFlag;
}
