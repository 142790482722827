import { Box, Button, List, ListItem, Text } from '@chakra-ui/react';
import RightArrow from 'src/assets/icons/RightArrow';
import { useSubscriptionCache } from 'src/apollo/cache';
import assert from 'src/utils/assert';
import getPrettyDate from 'src/utils/getPrettyDate';
import { ShowsDocument, useRemoveShowMutation } from 'src/generated/graphql';
import SupportLink from '../intercom/SupportLink';
import { useMyShowsContext } from './MyShowsContext';

export default function RemoveShow() {
  const { showToRemove, setShowToRemove, setContentType } = useMyShowsContext();
  assert(showToRemove, 'showToRemove not found in context, expected a show id');
  const [removeShow, { loading }] = useRemoveShowMutation({
    variables: {
      id: showToRemove,
    },
    onCompleted: () => {
      setShowToRemove(null);
      setContentType('show-removed-confirmation');
    },
    refetchQueries: [ShowsDocument],
  });
  const subscription = useSubscriptionCache();
  assert(subscription, 'subscription not found in cache');

  const prettyDate = getPrettyDate({
    date: String(subscription.current_period_end),
    shortDate: true,
    isUnixTimestamp: true,
  });

  return (
    <Box>
      <Text marginBottom="12px">Here&apos;s what will happen:</Text>
      <List listStyleType="disc" marginLeft="20px" spacing={2}>
        <ListItem>{`you will loose access to your show on ${prettyDate}`}</ListItem>
        <ListItem>
          if you host with Alitu, your podcast will no longer be accessible by the date above
        </ListItem>
        <ListItem>files tied to this show will no longer be accessible</ListItem>
      </List>
      <Text marginTop="12px">
        Feel free to{' '}
        <SupportLink message={`Hi, I'd like to remove my show. Can you help me with that?`}>
          contact our support team
        </SupportLink>{' '}
        if you have any further questions.
      </Text>
      <Button
        width="100%"
        marginTop="60px"
        variant="destructive"
        rightIcon={<RightArrow />}
        onClick={() => removeShow()}
        isLoading={loading}
      >
        Delete my show
      </Button>
    </Box>
  );
}
