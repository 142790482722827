import { useMemo } from 'react';
import { useUserCache } from 'src/apollo/cache';

export default function useShouldIntercomInitialise() {
  const user = useUserCache();

  return useMemo(
    () => process.env.NODE_ENV === 'production' && user?.role !== 'admin',
    [user?.role],
  );
}
